import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Slider, Upload } from 'antd';
import AvatarEditor from 'react-avatar-editor';

const ConsumerAvatarUpload = (props) => {
    const { profileImage, contributorUploadImage, refetch, consumerPage, uploadingImage, handleAvatarModal } = props;

    const [imageFile, setImageFile] = useState(null);
    const [imageScale, setImageScale] = useState(1.2);
    const editor = useRef();

    const onChangeScale = (value) => {
        setImageScale(value);
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        message.config({ top: 24 });

        if (editor) {
            const canvasScaled = editor.current.getImageScaledToCanvas();
            try {
                canvasScaled.toBlob(async (blob) => {
                    await contributorUploadImage({
                        variables: {
                            id: consumerPage?.id,
                            type: 'profileImage',
                            file: blob,
                        },
                    })
                        .then(() => {
                            message.destroy();
                            message.loading('Setting your logo');
                            refetch();
                            handleAvatarModal();
                        })
                        .catch(() => {
                            message.destroy();
                            message.error('There was an error uploading your logo. Please try again later.');
                        });
                });
            } catch (error) {
                console.log(error);
                message.destroy();
                message.error('There was an error uploading your logo. Please try again later.');
            }
        }
    };

    const onBeforeUpload = (file) => {
        message.config({ top: 24 });
        const imageFormats = ['image/jpeg', 'image/png'];

        if (!imageFormats.includes(file.type)) {
            message.destroy();
            message.error('You can only upload JPG or PNG file!');
            return false;
        }

        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isLt2M) {
            message.destroy();
            message.error('Image must be smaller than 2MB!');
            return false;
        }

        setImageFile(file);
        return false;
    };

    const newAvatar = imageFile || profileImage;

    return (
        <>
            <div className="flex justify-center align-middle">
                <AvatarEditor
                    ref={editor}
                    image={newAvatar}
                    width={300}
                    height={300}
                    border={50}
                    color={[0, 0, 0, 0.7]}
                    scale={imageScale}
                    rotate={0}
                    crossOrigin="anonymous"
                />
            </div>
            <div className="px-2 pt-2">
                <Upload
                    accept=".jpeg, .JPEG, .png, .PNG, .jpg, .JPG"
                    className="block w-full"
                    name="user-avatar"
                    showUploadList={false}
                    beforeUpload={onBeforeUpload}
                >
                    <div className="p-2">
                        <div className="pb-2 text-sm italic">
                            *Valid format are only JPG/JPEG or PNG images and must not be greater than 2MB.
                        </div>
                        <Button className="w-full">
                            <UploadOutlined /> Select a photo
                        </Button>
                    </div>
                </Upload>

                <Slider step={0.1} min={0.6} max={2} onChange={onChangeScale} defaultValue={1.2} />

                <Button
                    size="large"
                    type="primary"
                    className="mt-2 w-full"
                    onClick={handleUpload}
                    loading={uploadingImage}
                >
                    {uploadingImage ? 'Saving' : 'Save'}
                </Button>
            </div>
        </>
    );
};

ConsumerAvatarUpload.propTypes = {
    profileImage: PropTypes.string,
    uploadingImage: PropTypes.bool,
    contributorUploadImage: PropTypes.func,
    refetch: PropTypes.func,
    consumerPage: PropTypes.func,
    handleAvatarModal: PropTypes.func,
};
ConsumerAvatarUpload.defaultProps = {
    uploadingImage: false,
    profileImage: null,
    contributorUploadImage: () => {},
    refetch: () => {},
    consumerPage: {},
    handleAvatarModal: () => {},
};

export default ConsumerAvatarUpload;
