import { useQuery } from '@apollo/client';
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { CurrentConsumer } from './Operations.graphql';
import PRIMARY from '~/constants/colors';

const BusinessPostAvatar = ({ pageId }) => {
    const { data, loading } = useQuery(CurrentConsumer, { variables: { pageId } });

    if (loading) {
        return (
            <Avatar
                style={{
                    color: PRIMARY,
                    backgroundColor: '#fff',
                    border: `2px solid ${PRIMARY}`,
                    width: 43,
                    height: 43,
                }}
                icon={<UserOutlined />}
                size="large"
            />
        );
    }

    const { consumerPage } = data || {};
    const { profileImage, initials } = consumerPage || {};

    return (
        <Avatar
            style={{
                color: PRIMARY,
                backgroundColor: '#fff',
                border: `2px solid ${PRIMARY}`,
                width: 43,
                height: 43,
            }}
            size="large"
            src={`${profileImage}?w=50`}
            alt="avatar"
        >
            {initials}
        </Avatar>
    );
};

BusinessPostAvatar.propTypes = { pageId: PropTypes.string };

BusinessPostAvatar.defaultProps = { pageId: null };

export default BusinessPostAvatar;
