import { useQuery } from '@apollo/client';
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import uniqBy from 'lodash/uniqBy';
import {
    AutoSizer,
    CellMeasurer,
    CellMeasurerCache,
    InfiniteLoader,
    List as VList,
    WindowScroller,
} from 'react-virtualized';
import { EmitterContext, FeedEventsNames } from '../../context/emitter-context';
import ConsumerEventActions from '../ConsumerEventActions';
import Error from '../Error';
import FeedPostEvent from '../FeedPostEvent';
import LoadingDots from '../LoadingDots';
import ConsumerPageEventsConnection from './Operations.graphql';
import './BusinessPosts.module.scss';

const BusinessPosts = ({ consumerPageId, consumerPage, pageId, emitter }) => {
    const { data, loading, error, fetchMore, refetch } = useQuery(ConsumerPageEventsConnection, {
        variables: {
            pageId,
            consumerPageId,
            filter: { first: 20 },
        },
    });
    const [loadingMore, setLoadingMore] = useState(false);
    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 300,
    });
    const vlist = useRef();

    const loadedRowsMap = [];

    const recalculateHeightAndClearCache = () => {
        cache.clearAll();
        if (vlist) {
            vlist.current.recomputeRowHeights();
        }
        refetch();
    };

    const recalculateHeight = (index) => {
        cache.clearAll();

        if (vlist) {
            vlist.current.recomputeRowHeights(index);
        }
    };

    useEffect(() => {
        emitter.addListener(FeedEventsNames.ON_POST_ADDED, () => {
            console.log('ON_POST_ADDED');
            recalculateHeightAndClearCache();
        });

        emitter.addListener(FeedEventsNames.ON_POST_DELETED, () => {
            console.log('ON_POST_DELETED');
            recalculateHeightAndClearCache();
        });

        return function cleanup() {
            emitter.removeListener(FeedEventsNames.ON_POST_ADDED, () => {});
            emitter.removeListener(FeedEventsNames.ON_POST_DELETED, () => {});
        };
    }, []);

    const handleInfiniteOnLoad = async ({ startIndex, stopIndex }) => {
        const { consumerPage: consumerPageData } = data;

        const { consumerPageEventsConnection } = consumerPageData;
        const businessPosts = uniqBy(consumerPageEventsConnection.nodes, 'id') || [];
        setLoadingMore(true);
        for (let i = startIndex; i <= stopIndex; i += 1) {
            loadedRowsMap[i] = 1;
        }

        if (stopIndex >= businessPosts.length - 5) {
            return new Promise(async (resolve) => {
                try {
                    await fetchMore({
                        variables: {
                            filter: {
                                after: consumerPageData.consumerPageEventsConnection.pageInfo.endCursor,
                                first: 12,
                            },
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const { nodes } = fetchMoreResult.consumerPage.consumerPageEventsConnection;

                            return nodes.length
                                ? {
                                      consumerPage: {
                                          ...fetchMoreResult.consumerPage,
                                          consumerPageEventsConnection: {
                                              ...fetchMoreResult.consumerPage.consumerPageEventsConnection,
                                              nodes: [
                                                  ...uniqBy(
                                                      previousResult.consumerPage.consumerPageEventsConnection.nodes,
                                                      'id'
                                                  ),
                                                  ...uniqBy(nodes, 'id'),
                                              ],
                                          },
                                      },
                                  }
                                : previousResult;
                        },
                    });
                    resolve(true);
                } catch (e) {
                    console.log(e);
                } finally {
                    setLoadingMore(false);
                }
            });
        }
    };

    const isRowLoaded = ({ index }) => !!loadedRowsMap[index];

    const parseItem = (event, index) => {
        const { id, uuid, post } = event;

        return (
            post && (
                <div
                    key={id}
                    className="consumer-event-card mb-4 overflow-hidden border border-gray-300 bg-white text-xs sm:rounded-lg sm:shadow-sm"
                >
                    <div className="pt-4 pb-6">
                        <FeedPostEvent
                            consumerPage={consumerPage}
                            event={event}
                            onEdit={recalculateHeightAndClearCache}
                            onDelete={recalculateHeightAndClearCache}
                            recalculateHeight={() => recalculateHeight(index)}
                            pageId={pageId}
                            consumerPageId={consumerPageId}
                        />
                    </div>
                    <div className="pt-2">
                        <ConsumerEventActions id={id} uuid={uuid} />
                    </div>
                </div>
            )
        );
    };

    const renderItem = ({ index, key, style, parent }) => {
        const { consumerPage: consumerPageData } = data;
        const { consumerPageEventsConnection } = consumerPageData;
        const businessPosts = uniqBy(consumerPageEventsConnection.nodes, 'id') || [];

        const item = businessPosts[index];
        return (
            <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
                <div style={style} key={key}>
                    {parseItem(item, index)}
                </div>
            </CellMeasurer>
        );
    };

    const { consumerPage: consumerPageData } = data || {};

    if (loading) {
        return (
            <>
                {Array(...Array(3)).map((v, i) => (
                    <Skeleton
                        loading
                        active
                        avatar={{ size: 52 }}
                        paragraph={{ rows: 2 }}
                        className="consumer-event-skeleton mb-4 rounded-lg border-b border-gray-300 bg-white p-4 sm:border-b-0 sm:shadow-sm"
                        key={i.toString()}
                    />
                ))}
            </>
        );
    }

    if (error) return <Error error={error} />;

    const { consumerPageEventsConnection } = consumerPageData;
    const businessPosts = uniqBy(consumerPageEventsConnection.nodes, 'id') || [];
    const loadMore = loadingMore && <LoadingDots />;

    return (
        <>
            {businessPosts.length > 0 ? (
                <>
                    <WindowScroller>
                        {({ height, isScrolling, onChildScroll, scrollTop }) => (
                            <InfiniteLoader
                                isRowLoaded={isRowLoaded}
                                loadMoreRows={handleInfiniteOnLoad}
                                rowCount={businessPosts.length}
                            >
                                {({ onRowsRendered }) => (
                                    <AutoSizer disableHeight>
                                        {({ width }) => (
                                            <VList
                                                autoHeight
                                                height={height}
                                                isScrolling={isScrolling}
                                                onScroll={onChildScroll}
                                                overscanRowCount={2}
                                                rowCount={businessPosts.length}
                                                rowHeight={cache.rowHeight}
                                                // rowHeight={73}
                                                rowRenderer={renderItem}
                                                onRowsRendered={onRowsRendered}
                                                deferredMeasurementCache={cache}
                                                scrollTop={scrollTop}
                                                width={width}
                                                ref={vlist}
                                                className="consumer-events-list"
                                            />
                                        )}
                                    </AutoSizer>
                                )}
                            </InfiniteLoader>
                        )}
                    </WindowScroller>
                    {!consumerPageEventsConnection.pageInfo.hasNextPage && (
                        <p className="text-center text-gray-500">No more posts available.</p>
                    )}
                </>
            ) : (
                <div className="relative h-64 rounded-lg bg-white text-center shadow">
                    <h2 className="center-align pt-2 pb-1 text-gray-700">No Feed</h2>
                </div>
            )}
            {loadMore}
        </>
    );
};

BusinessPosts.propTypes = {
    consumerPage: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    pageId: PropTypes.string,
    consumerPageId: PropTypes.string,
    emitter: PropTypes.object,
};

BusinessPosts.defaultProps = {
    consumerPage: {},
    isAuthenticated: false,
    pageId: null,
    consumerPageId: null,
    emitter: {},
};

const BusinessPostsComponent = (props) => (
    <EmitterContext.Consumer>{(emitter) => <BusinessPosts emitter={emitter} {...props} />}</EmitterContext.Consumer>
);

export default BusinessPostsComponent;
