import { gql } from '@apollo/client';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { graphql } from '@apollo/client/react/hoc';
import { PlusOutlined } from '@ant-design/icons';
import { Avatar, Card } from 'antd';
import compose from 'lodash.flowright';
import Error from '~/components/Error';
import FollowCardTitleCount from '~/components/FollowCardTitleCount';
import { FollowedContributorQuery } from './Operations.graphql';
import PRIMARY from '~/constants/colors';

class ConsumerProfileFollowingContributorList extends PureComponent {
    static propTypes = {
        data: PropTypes.object,
        editable: PropTypes.bool,
    };

    static defaultProps = {
        data: {},
        editable: false,
    };

    render() {
        const {
            data: { loading, error, consumerPage },
            editable,
        } = this.props;

        if (error) return <Error error={error} />;

        const { owner } = consumerPage || {};
        const { followedConsumersConnection } = owner || {};
        const followedContributors = followedConsumersConnection ? followedConsumersConnection.nodes : [];

        return (
            <Card
                loading={loading}
                bodyStyle={{ padding: 0 }}
                bordered={false}
                className="rounded shadow"
                title={<FollowCardTitleCount title="Contributors" count={followedContributors.length} />}
                extra={
                    editable && (
                        <Link href="/lifestyle" as="/lifestyle" className="rounded-full p-2">
                            <PlusOutlined />
                        </Link>
                    )
                }
            >
                <main className="flex flex-wrap px-6 py-3">
                    {followedContributors.length > 0 ? (
                        <>
                            {followedContributors.map(({ id, initials, firstName, lastName, profilePicture }) => (
                                <Avatar
                                    className={`contributor-avatar mb-2 mr-2 bg-white font-bold avatar-${initials[0].toLowerCase()}`}
                                    src={profilePicture}
                                    key={id}
                                    size={50}
                                    title={`${firstName}${' '}${lastName}`}
                                    style={{ border: `2px solid ${PRIMARY}`, color: PRIMARY }}
                                >
                                    {initials}
                                </Avatar>
                            ))}
                        </>
                    ) : (
                        <span>No contributors yet</span>
                    )}
                </main>
            </Card>
        );
    }
}

const CONSUMER_FOLLOW_CONTRIBUTOR = gql`
    mutation consumerFollowConsumer($followeeId: ID!) {
        consumerFollowConsumer(followeeId: $followeeId)
    }
`;
const CONSUMER_UNFOLLOW_CONTRIBUTOR = gql`
    mutation consumerUnfollowConsumer($followeeId: ID!) {
        consumerUnfollowConsumer(followeeId: $followeeId)
    }
`;

export default compose(
    graphql(FollowedContributorQuery, {
        options: ({ pageId }) => ({
            variables: {
                pageId,
                filter: { first: 7 },
            },
        }),
    }),
    graphql(CONSUMER_FOLLOW_CONTRIBUTOR, {
        name: 'consumerFollowConsumer',
        props: ({ consumerFollowConsumer }) => ({
            consumerFollowConsumer: (followeeId) => consumerFollowConsumer({ variables: { followeeId } }),
        }),
    }),
    graphql(CONSUMER_UNFOLLOW_CONTRIBUTOR, {
        name: 'consumerUnfollowConsumer',
        props: ({ consumerUnfollowConsumer }) => ({
            consumerUnfollowConsumer: (followeeId) => consumerUnfollowConsumer({ variables: { followeeId } }),
        }),
    })
)(ConsumerProfileFollowingContributorList);
