/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import BusinessPageAbout from '../BusinessPageAbout';
import BusinessPageHome from '../BusinessPageHome';
import BusinessPagePhotos from '../BusinessPagePhotos';
import BusinessPagePosts from '../BusinessPagePosts';

const BusinessPageRoutes = ({ isPageOwner, isAuthenticated, consumerPage, refetch, subRoute }) => {
    const displaySubRoutePage = () => {
        let subRoutePage = null;
        switch (subRoute) {
            case 'about': {
                subRoutePage = (
                    <BusinessPageAbout
                        isPageOwner={isPageOwner}
                        isAuthenticated={isAuthenticated}
                        consumerPage={consumerPage}
                        refetch={refetch}
                    />
                );
                break;
            }
            case 'posts': {
                subRoutePage = (
                    <BusinessPagePosts
                        isAuthenticated={isAuthenticated}
                        consumerPage={consumerPage}
                        refetch={refetch}
                    />
                );
                break;
            }
            case 'photos': {
                subRoutePage = <BusinessPagePhotos consumerPage={consumerPage} />;
                break;
            }
            default: {
                subRoutePage = (
                    <BusinessPageHome
                        isPageOwner={isPageOwner}
                        isAuthenticated={isAuthenticated}
                        consumerPage={consumerPage}
                        refetch={refetch}
                    />
                );
                break;
            }
        }

        return subRoutePage;
    };

    return displaySubRoutePage();
};

BusinessPageRoutes.propTypes = {
    isPageOwner: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    consumerPage: PropTypes.object,
    refetch: PropTypes.func,
    subRoute: PropTypes.string,
};

BusinessPageRoutes.defaultProps = {
    isPageOwner: false,
    isAuthenticated: false,
    consumerPage: {},
    refetch: () => {},
    subRoute: null,
};

export default BusinessPageRoutes;
