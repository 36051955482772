/* eslint-disable max-len */
import { gql } from '@apollo/client';
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { CameraOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import compose from 'lodash.flowright';
import ClaimAgentSocialButton from '~/components/ClaimAgentSocialButton';
import ConsumerAvatarModal from '~/components/ConsumerAvatarModal';
import FollowConsumerButton from '~/components/FollowConsumerButton';
import FriendRequestButton from '~/components/FriendRequestButton';
import PRIMARY from '~/constants/colors';
import './AgentProfileBanner.module.scss';

class AgentProfileBanner extends PureComponent {
    static propTypes = {
        consumerPage: PropTypes.object,
        contributorUploadImage: PropTypes.func,
        refetch: PropTypes.func,
    };

    static defaultProps = {
        consumerPage: () => {},
        contributorUploadImage: () => {},
        refetch: () => {},
    };

    state = {
        uploading: null,
        showAvatarModal: false,
    };

    customRequest = (key, { file, onSuccess, onError }) =>
        new Promise((resolve) => {
            message.config({ top: 24 });

            const imageFormats = ['image/jpeg', 'image/png'];
            const exceededFileSize = key === 'profileImage' ? file.size > 2000000 : file.size > 3000000;

            if (!imageFormats.includes(file.type)) {
                return false;
            }
            if (exceededFileSize) {
                return false;
            }

            this.setState({ uploading: key });

            const { contributorUploadImage, consumerPage, refetch } = this.props;

            contributorUploadImage(consumerPage.id, key, file)
                .then(({ data: { contributorUploadImage } }) => {
                    onSuccess(contributorUploadImage, file);
                    refetch();
                    setTimeout(() => {
                        this.setState({ uploading: null });
                    }, 3000);
                    resolve(true);
                })
                .catch(() => {
                    onError();
                    this.setState({ uploading: null });
                    message.destroy();
                    message.error('Upload failed');
                });
        });

    handleUpload = (key, info) => {
        message.config({ top: 24 });
        const { fileList } = info;

        const imageFormats = ['image/jpeg', 'image/png'];
        const newFileList = fileList.filter((item) => imageFormats.includes(item.type));
        const validFileSize =
            key === 'profile'
                ? fileList.filter((item) => item.size <= 2000000)
                : fileList.filter((item) => item.size <= 3000000);

        if (newFileList.length < 1) {
            message.destroy();
            message.error('You can only upload JPG or PNG file!');
            return false;
        }

        if (validFileSize.length < 1) {
            message.destroy();
            message.error(`Image must be smaller than ${key === 'profile' ? '(2MB)' : '(3MB)'}.`);
            return false;
        }
    };

    handleAvatarModal = () => {
        this.setState(({ showAvatarModal }) => ({
            showAvatarModal: !showAvatarModal,
        }));
    };

    render() {
        const { consumerPage, refetch, contributorUploadImage } = this.props;
        const { uploading, showAvatarModal } = this.state;

        const {
            owner: { firstName, lastName, initials, id, friendInfo },
            profileImage,
            coverImage,
            pageId,
            isPageOwner,
            isFollowed,
            isClaimed,
            id: consumerPageId,
        } = consumerPage || {};

        return (
            <main
                className="AgentProfileBanner relative z-0 p-4 pt-6 transition"
                style={{ background: coverImage ? `url(${coverImage}) center top / cover no-repeat` : PRIMARY }}
            >
                {!coverImage && isPageOwner && (
                    <div className="pin absolute z-20 pt-3 pl-3 sm:inset-auto sm:right-0 sm:pt-0 sm:pl-0 sm:pr-4 lg:inset-auto lg:w-full lg:pt-8 lg:pr-0 lg:text-center">
                        <Upload
                            accept=".jpeg, .JPEG, .png, .PNG, .jpg, .JPG"
                            showUploadList={false}
                            customRequest={(info) => {
                                this.customRequest('coverImage', info);
                            }}
                            onChange={(info) => {
                                this.handleUpload('cover', info);
                            }}
                        >
                            <Button
                                className="font-bold"
                                type="primary"
                                loading={uploading === 'coverImage'}
                                icon={<UploadOutlined />}
                            >
                                {uploading === 'coverImage' ? 'Uploading' : 'Upload Cover Photo'}
                            </Button>
                        </Upload>
                    </div>
                )}

                {coverImage && isPageOwner && (
                    <div className="pin absolute z-20 pt-3 pl-3 sm:inset-auto sm:right-0 sm:pt-0 sm:pl-0 sm:pr-4">
                        <Upload
                            accept=".jpeg, .JPEG, .png, .PNG, .jpg, .JPG"
                            showUploadList={false}
                            customRequest={(info) => {
                                this.customRequest('coverImage', info);
                            }}
                            onChange={(info) => {
                                this.handleUpload('cover', info);
                            }}
                        >
                            <Button
                                className="font-bold"
                                type="primary"
                                loading={uploading === 'coverImage'}
                                icon={<EditOutlined />}
                            >
                                {uploading === 'coverImage' ? 'Uploading' : 'Edit Cover Photo'}
                            </Button>
                        </Upload>
                    </div>
                )}

                <section className="absolute bottom-0 left-0 flex w-full flex-col items-center justify-center sm:flex-row">
                    {!profileImage && (
                        <div className="noAvatar">
                            <div className="noAvatarButtonWrapper">
                                {isPageOwner ? (
                                    <Button
                                        className="font-bold"
                                        size="small"
                                        type="primary"
                                        icon={<CameraOutlined />}
                                        onClick={this.handleAvatarModal}
                                    >
                                        Upload Picture
                                    </Button>
                                ) : (
                                    <span style={{ color: PRIMARY }} className="text-3xl md:text-5xl">
                                        {initials}
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                    {profileImage && (
                        <div
                            className="hasAvatar relative"
                            style={{ background: `#fff url(${profileImage}) center / cover no-repeat` }}
                        >
                            {isPageOwner && (
                                <div className="editProfileImageButton absolute z-10">
                                    <Button
                                        type="primary"
                                        shape="circle"
                                        icon={<EditOutlined />}
                                        onClick={this.handleAvatarModal}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    <ConsumerAvatarModal
                        handleAvatarModal={this.handleAvatarModal}
                        showAvatarModal={showAvatarModal}
                        contributorUploadImage={contributorUploadImage}
                        refetch={refetch}
                        consumerPage={consumerPage}
                    />
                    <div className="Desktop flex w-full grow justify-between p-2 text-center sm:text-left md:w-auto md:pt-12">
                        <div>
                            <h2 className="text-overflow text-white">
                                {firstName} {lastName}
                            </h2>
                            <h4 className="text-overflow py-1 font-medium text-primary-500">@{pageId}</h4>
                            <div className="inline-block rounded-full bg-primary-500 py-1 px-4 font-medium text-white">
                                Agent
                            </div>
                        </div>
                        <div className="flex flex-wrap items-center justify-center sm:justify-between">
                            {!isPageOwner && (
                                <div>
                                    <span className="mx-1">
                                        <FriendRequestButton
                                            isPageOwner={isPageOwner}
                                            pageOwnerId={id}
                                            friendInfo={friendInfo}
                                            refetch={refetch}
                                        />
                                    </span>
                                    <span className="mx-1">
                                        <FollowConsumerButton
                                            isPageOwner={isPageOwner}
                                            consumerId={id}
                                            isFollowed={isFollowed}
                                            refetch={refetch}
                                        />
                                    </span>
                                    <span className="mx-1">
                                        <ClaimAgentSocialButton
                                            isPageOwner={isPageOwner}
                                            consumerId={id}
                                            consumerPageId={consumerPageId}
                                            isClaimed={isClaimed}
                                            refetch={refetch}
                                        />
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

const CONTRIBUTOR_UPLOAD_IMAGE_MUTATION = gql`
    mutation contributorUploadImage($id: ID!, $type: String, $file: Upload!) {
        contributorUploadImage(id: $id, type: $type, file: $file)
    }
`;

export default compose(
    graphql(CONTRIBUTOR_UPLOAD_IMAGE_MUTATION, {
        props: ({ mutate }) => ({
            contributorUploadImage: (id, type, file) =>
                mutate({
                    variables: {
                        id,
                        type,
                        file,
                    },
                }),
        }),
    })
)(AgentProfileBanner);
