/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import BusinessAvatarUpload from '~/components/BusinessAvatarUpload';

const BusinessAvatarModal = ({
    profileImage,
    handleAvatarModal,
    showAvatarModal,
    contributorUploadImage,
    uploadingImage,
    refetch,
    consumerPageId,
}) => (
    <Modal
        wrapClassName="avatar-upload-modal"
        title="Change Profile Picture"
        visible={showAvatarModal}
        onCancel={handleAvatarModal}
        footer={null}
        onOk={handleAvatarModal}
        destroyOnClose
        maskClosable={false}
    >
        <BusinessAvatarUpload
            profileImage={profileImage}
            contributorUploadImage={contributorUploadImage}
            uploadingImage={uploadingImage}
            refetch={refetch}
            consumerPageId={consumerPageId}
            handleAvatarModal={handleAvatarModal}
        />
    </Modal>
);

BusinessAvatarModal.propTypes = {
    profileImage: PropTypes.string,
    handleAvatarModal: PropTypes.func,
    showAvatarModal: PropTypes.bool,
    contributorUploadImage: PropTypes.func,
    refetch: PropTypes.func,
    consumerPageId: PropTypes.string,
    uploadingImage: PropTypes.bool,
};

BusinessAvatarModal.defaultProps = {
    profileImage: null,
    handleAvatarModal: () => {},
    showAvatarModal: false,
    contributorUploadImage: () => {},
    refetch: () => {},
    consumerPageId: {},
    uploadingImage: false,
};

export default BusinessAvatarModal;
