import React from 'react';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { APOLLO_STATE_PROP_NAME } from '~/lib/apollo/apollo';
import { isBrowser } from '~/utils/env';
import initApollo from './initApollo';

const withApolloProvider = (ComposedComponent, { ssr = true } = {}) => {
    const WithApollo = ({ apollo, apolloState, ...pageProps }) => {
        const client = apollo || initApollo(apolloState);

        return (
            <ApolloProvider client={client}>
                <ComposedComponent {...pageProps} />
            </ApolloProvider>
        );
    };

    // Set the correct displayName in development
    if (process.env.NODE_ENV !== 'production') {
        const displayName = ComposedComponent.displayName || ComposedComponent.name || 'Component';

        if (displayName === 'App') {
            console.warn('This withApollo HOC only works with PageComponents.');
        }

        WithApollo.displayName = `withApollo(${displayName})`;
    }

    if (ssr || ComposedComponent.getInitialProps) {
        WithApollo.getInitialProps = async (ctx) => {
            const { AppTree, req } = ctx;

            const apollo = initApollo();
            ctx.apolloClient = apollo;
            ctx.req = req;

            // Evaluate the composed component's getInitialProps()
            let pageProps = {};
            if (ComposedComponent.getInitialProps) {
                pageProps = await ComposedComponent.getInitialProps(ctx);
            }

            if (!isBrowser) {
                // When redirecting, the response is finished.
                // No point in continuing to render
                if (ctx.res && ctx.res.finished) {
                    return pageProps;
                }

                // Only if ssr is enabled
                if (ssr) {
                    try {
                        const { getDataFromTree } = await import('@apollo/client/react/ssr');

                        console.info('Starting SSR getDataFromTree ');
                        console.log('pageProps');
                        console.log(pageProps);
                        // Run all GraphQL queries
                        await getDataFromTree(
                            <AppTree
                                pageProps={{
                                    ...pageProps,
                                    apollo,
                                }}
                            />
                        );

                        // await getDataFromTree(<AppTree {...pageProps} apolloClient={apollo} />);
                        console.info('Success SSR getDataFromTree ');
                    } catch (error) {
                        console.warn('SSR error');
                        console.log(error);
                        console.warn(JSON.stringify(error, null, 2));

                        // captureException(error, ctx.ctx);
                        Sentry.captureException(error);
                        // Prevent Apollo Client GraphQL errors from crashing SSR.
                        // Handle them in components via the data.error prop:
                        // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
                    }
                    // getDataFromTree does not call componentWillUnmount
                    // head side effect therefore need to be cleared manually
                    // https://github.com/vercel/next.js/blob/canary/docs/upgrading.md#remove-headrewind
                    // Head.rewind();
                }
            }

            const result = {
                // serverState,
                ...pageProps,
            };

            // Extract query data from the Apollo store
            // serverState = { apollo: { data: apollo.cache.extract() } };
            result[APOLLO_STATE_PROP_NAME] = apollo.cache.extract();

            return result;
        };
        return WithApollo;
    }
    return WithApollo;
};
export default withApolloProvider;
