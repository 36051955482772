import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { Button, Modal } from 'antd';
import compose from 'lodash.flowright';
import { connect } from 'react-redux';
import { loginUser } from '../../actions';
import GuestLoginForm from '../GuestLoginForm';
import { FollowConsumerMutation, UnfollowConsumerMutation } from './Operations.graphql';

class FollowConsumerButton extends Component {
    static propTypes = {
        consumerId: PropTypes.string.isRequired,
        userType: PropTypes.string,
        onLoginUser: PropTypes.func,
        isAuthenticated: PropTypes.bool,
        isFollowed: PropTypes.bool,
        followConsumer: PropTypes.func,
        unFollowConsumer: PropTypes.func,
        isPageOwner: PropTypes.bool,
        refetch: PropTypes.func,
    };

    static defaultProps = {
        userType: null,
        onLoginUser: () => {},
        isAuthenticated: false,
        isFollowed: false,
        followConsumer: () => {},
        unFollowConsumer: () => {},
        isPageOwner: false,
        refetch: () => {},
    };

    constructor(props) {
        super(props);
        const { isFollowed } = this.props;

        this.state = {
            consumerLoginDecisionModal: false,
            isLoading: false,
            isFollowedByConsumer: isFollowed,
        };
    }

    onLoginSuccess = loginData => {
        const { onLoginUser, consumerId, followConsumer, refetch } = this.props;
        const { isFollowedByConsumer } = this.state;

        const { accessToken, expiresIn, refreshToken, user, type } = loginData;
        onLoginUser({
            accessToken,
            expiresIn,
            refreshToken,
            user,
            type,
        });

        this.setState({ isLoading: true });

        if (user.id !== consumerId) {
            followConsumer(consumerId).then(() => {
                this.setState(
                    {
                        isFollowedByConsumer: !isFollowedByConsumer,
                        isLoading: false,
                    },
                    () => {
                        this.closeConsumerLoginModal();
                    }
                );
            });
        }
        refetch();
    };

    showDecisionModal = bool => {
        this.setState({ consumerLoginDecisionModal: bool });
    };

    closeConsumerLoginModal = () => {
        this.setState({ consumerLoginDecisionModal: false });
    };

    toggleFollowButton = async () => {
        const { consumerId, followConsumer, unFollowConsumer } = this.props;
        const { isFollowedByConsumer } = this.state;
        this.setState({ isLoading: true });

        if (isFollowedByConsumer) {
            await unFollowConsumer(consumerId);
        } else {
            await followConsumer(consumerId);
        }
        this.setState({
            isFollowedByConsumer: !isFollowedByConsumer,
            isLoading: false,
        });
    };

    handleFollowButtonClick = () => {
        const { isAuthenticated } = this.props;

        if (isAuthenticated) {
            this.toggleFollowButton();
        } else {
            this.showDecisionModal(true);
        }
    };

    render() {
        const { isAuthenticated, userType, isPageOwner } = this.props;
        const { isFollowedByConsumer, isLoading, consumerLoginDecisionModal } = this.state;
        const buttonText = isFollowedByConsumer ? 'Unfollow' : 'Follow';

        if (isPageOwner) return null;

        return (
            <div className="FollowConsumerButton inline-block">
                <div className="relative -mt-4">
                    {isAuthenticated && userType !== 'CONSUMER' && <span />}
                    {!isAuthenticated && (
                        <Button type="primary" onClick={this.handleFollowButtonClick} loading={isLoading}>
                            {buttonText}
                        </Button>
                    )}
                    {isAuthenticated && userType === 'CONSUMER' && (
                        <Button type="primary" onClick={this.handleFollowButtonClick} loading={isLoading}>
                            {buttonText}
                        </Button>
                    )}
                </div>
                <Modal
                    centered
                    visible={consumerLoginDecisionModal}
                    onOk={() => this.showDecisionModal(false)}
                    onCancel={() => this.showDecisionModal(false)}
                    footer={null}
                    zIndex={1060}
                >
                    <GuestLoginForm onSuccess={this.onLoginSuccess} onSuccessAccount={this.onLoginSuccess} />
                </Modal>
            </div>
        );
    }
}

export default compose(
    graphql(FollowConsumerMutation, {
        name: 'followConsumer',
        props: ({ followConsumer }) => ({
            followConsumer: consumerId => followConsumer({ variables: { consumerId } }),
        }),
    }),
    graphql(UnfollowConsumerMutation, {
        name: 'unFollowConsumer',
        props: ({ unFollowConsumer }) => ({
            unFollowConsumer: consumerId => unFollowConsumer({ variables: { consumerId } }),
        }),
    }),
    connect(
        state => ({
            isAuthenticated: state.auth.isAuthenticated,
            userType: state.auth.type,
        }),
        dispatch => ({
            onLoginUser(accessToken) {
                dispatch(loginUser(accessToken));
            },
        })
    )
)(FollowConsumerButton);
