import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { Skeleton } from 'antd';
import compose from 'lodash.flowright';
import uniqBy from 'lodash/uniqBy';
import {
    AutoSizer,
    CellMeasurer,
    CellMeasurerCache,
    InfiniteLoader,
    List as VList,
    WindowScroller,
} from 'react-virtualized';
// import { connect } from 'react-redux';
import { EmitterContext, FeedEventsNames } from '~/context/emitter-context';
import ConsumerEventActions from '~/components/ConsumerEventActions';
import Error from '~/components/Error';
import FeedPostEvent from '~/components/FeedPostEvent';
import LoadingDots from '~/components/LoadingDots';
import ConsumerPagePostConnectionQuery from './Operations.graphql';
import './ConsumerProfilePosts.module.scss';

class ConsumerProfilePosts extends Component {
    static propTypes = {
        data: PropTypes.oneOfType([PropTypes.object]),
        editable: PropTypes.bool,
        pageId: PropTypes.string,
        isAuthenticated: PropTypes.bool,
    };

    static defaultProps = {
        data: {},
        editable: false,
        pageId: null,
        isAuthenticated: false,
    };

    loadedRowsMap = {};

    constructor(props) {
        super(props);
        this.cache = new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 300,
        });

        this.state = { loadingMore: false };
    }

    componentDidMount() {
        const { emitter } = this.props;
        this.listener = emitter.addListener(FeedEventsNames.ON_POST_ADDED, () => {
            console.log('ON_POST_ADDED');
            this.recalculateHeightAndClearCache();
        });

        this.listener = emitter.addListener(FeedEventsNames.ON_POST_DELETED, () => {
            console.log('ON_POST_DELETED');

            this.recalculateHeightAndClearCache();
        });
    }

    componentWillUnmount() {
        const { emitter } = this.props;
        emitter.removeListener(FeedEventsNames.ON_POST_ADDED, () => {});

        emitter.removeListener(FeedEventsNames.ON_POST_DELETED, () => {});
    }

    handleInfiniteOnLoad = async ({ startIndex, stopIndex }) => {
        const {
            data: { fetchMore, consumerPage },
        } = this.props;
        const { profilePostsConnection } = consumerPage;
        const contributorPosts = uniqBy(profilePostsConnection.nodes, 'id') || [];
        this.setState({ loadingMore: true });
        for (let i = startIndex; i <= stopIndex; i += 1) {
            this.loadedRowsMap[i] = 1;
        }

        if (stopIndex >= contributorPosts.length - 5) {
            return new Promise(async (resolve) => {
                try {
                    await fetchMore({
                        variables: {
                            filter: {
                                after: consumerPage.profilePostsConnection.pageInfo.endCursor,
                                first: 12,
                            },
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const { nodes } = fetchMoreResult.consumerPage.profilePostsConnection;

                            return nodes.length
                                ? {
                                      consumerPage: {
                                          ...fetchMoreResult.consumerPage,
                                          profilePostsConnection: {
                                              ...fetchMoreResult.consumerPage.profilePostsConnection,
                                              nodes: [
                                                  ...uniqBy(
                                                      previousResult.consumerPage.profilePostsConnection.nodes,
                                                      'id'
                                                  ),
                                                  ...uniqBy(nodes, 'id'),
                                              ],
                                          },
                                      },
                                  }
                                : previousResult;
                        },
                    });
                    resolve(true);
                } catch (e) {
                    console.log(e);
                } finally {
                    this.setState({ loadingMore: false });
                }
            });
        }
    };

    isRowLoaded = ({ index }) => !!this.loadedRowsMap[index];

    parseItem = (event, index) => {
        const { isAuthenticated, pageId } = this.props;
        const { id, uuid, post } = event;
        return (
            post && (
                <div
                    key={id}
                    className="consumer-event-card mb-4 overflow-hidden border-b border-gray-200 bg-white bg-white text-xs sm:rounded-lg sm:border-b-0 sm:border-b-transparent sm:shadow-sm"
                >
                    <div className="pt-4 pb-6">
                        <FeedPostEvent
                            event={event}
                            onEdit={this.recalculateHeightAndClearCache}
                            pageId={pageId}
                            recalculateHeight={() => this.recalculateHeight(index)}
                        />
                    </div>
                    {isAuthenticated && (
                        <div className="pt-2">
                            <ConsumerEventActions id={id} uuid={uuid} />
                        </div>
                    )}
                </div>
            )
        );
    };

    renderItem = ({ index, key, style, parent }) => {
        const {
            data: { consumerPage },
        } = this.props;
        const { profilePostsConnection } = consumerPage;
        const consumerEvents = uniqBy(profilePostsConnection.nodes, 'id') || [];

        // const { id: loggedInId } = viewer || {};

        const item = consumerEvents[index];
        return (
            <CellMeasurer key={key} cache={this.cache} parent={parent} columnIndex={0} rowIndex={index}>
                <div style={style} key={key}>
                    {this.parseItem(item, index)}
                </div>
            </CellMeasurer>
        );
    };

    recalculateHeightAndClearCache = () => {
        this.cache.clearAll();
        if (this.vlist) {
            this.vlist.recomputeRowHeights();
        }
    };

    recalculateHeight = (index) => {
        this.cache.clearAll();
        if (this.vlist) {
            this.vlist.recomputeRowHeights(index);
        }
    };

    render() {
        const {
            data: { loading, consumerPage, error },
            editable,
        } = this.props;
        const { loadingMore } = this.state;

        if (loading) {
            return (
                <div className="mt-3">
                    {Array(...Array(3)).map((v, i) => (
                        <Skeleton
                            loading
                            active
                            avatar={{ size: 52 }}
                            paragraph={{ rows: 2 }}
                            className="consumer-event-skeleton mb-4 rounded-lg border-b border-gray-200 bg-white p-4 sm:border-b-0 sm:shadow-sm"
                            key={i.toString()}
                        />
                    ))}
                </div>
            );
        }

        if (error) return <Error error={error} />;

        const { profilePostsConnection } = consumerPage;
        const consumerEvents = uniqBy(profilePostsConnection.nodes, 'id') || [];
        const loadMore = loadingMore && <LoadingDots />;

        return (
            <div className="mt-3">
                {consumerEvents.length > 0 ? (
                    <>
                        <WindowScroller>
                            {({ height, isScrolling, onChildScroll, scrollTop }) => (
                                <InfiniteLoader
                                    isRowLoaded={this.isRowLoaded}
                                    loadMoreRows={this.handleInfiniteOnLoad}
                                    rowCount={consumerEvents.length}
                                >
                                    {({ onRowsRendered }) => (
                                        <AutoSizer disableHeight>
                                            {({ width }) => (
                                                <VList
                                                    autoHeight
                                                    height={height}
                                                    isScrolling={isScrolling}
                                                    onScroll={onChildScroll}
                                                    overscanRowCount={2}
                                                    rowCount={consumerEvents.length}
                                                    rowHeight={this.cache.rowHeight}
                                                    // rowHeight={73}
                                                    rowRenderer={this.renderItem}
                                                    onRowsRendered={onRowsRendered}
                                                    deferredMeasurementCache={this.cache}
                                                    scrollTop={scrollTop}
                                                    width={width}
                                                    ref={(ref) => {
                                                        this.vlist = ref;
                                                    }}
                                                    className="consumer-profile-events-list"
                                                />
                                            )}
                                        </AutoSizer>
                                    )}
                                </InfiniteLoader>
                            )}
                        </WindowScroller>
                        {!profilePostsConnection.pageInfo.hasNextPage && (
                            <p className="text-center text-gray-500">You&apos;ve reached the end</p>
                        )}
                    </>
                ) : (
                    <div className="relative h-64 rounded-lg bg-white text-center shadow">
                        <h2 className="center-align pt-2 pb-1 text-gray-700">
                            {editable
                                ? 'Build your own feed, start following agents, agencies & contributors now'
                                : 'No posts yet'}
                        </h2>
                    </div>
                )}
                {loadMore}
            </div>
        );
    }
}

const ConsumerProfilePostsList = compose(
    graphql(ConsumerPagePostConnectionQuery, {
        options: ({ pageId }) => ({
            ssr: false,
            variables: {
                pageId,
                filter: { first: 20 },
            },
        }),
    })
    // connect(state => ({ isAuthenticated: state.auth.isAuthenticated }))
)(ConsumerProfilePosts);

const ConsumerProfilePostsComponent = (props) => (
    <EmitterContext.Consumer>
        {(emitter) => <ConsumerProfilePostsList emitter={emitter} {...props} />}
    </EmitterContext.Consumer>
);

export default ConsumerProfilePostsComponent;
