import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import AgentPropertiesLocation from '~/components/AgentPropertiesLocation';
import AgentPropertyInfiniteList from '~/components/AgentPropertyInfiniteList';

const AgentPropertyTab = memo((props) => {
    const { id, group, sorting } = props;

    const [suburb, setSuburb] = useState('all');

    return (
        <>
            <div className="text-center">
                <AgentPropertiesLocation id={id} group={group} handleChange={(value) => setSuburb(value)} />
            </div>
            <AgentPropertyInfiniteList id={id} group={group} suburb={suburb} sorting={sorting} />
        </>
    );
});

AgentPropertyTab.propTypes = {
    id: PropTypes.string,
    group: PropTypes.string,
    sorting: PropTypes.string,
};

AgentPropertyTab.defaultProps = {
    id: '',
    group: 'BUY',
    sorting: null,
};

export default AgentPropertyTab;
