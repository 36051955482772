import { gql } from '@apollo/client';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { CameraOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import compose from 'lodash.flowright';
import ClaimAgentSocialButton from '~/components/ClaimAgentSocialButton';
import ConsumerAvatarModal from '~/components/ConsumerAvatarModal';
import FollowConsumerButton from '~/components/FollowConsumerButton';
import FriendRequestButton from '~/components/FriendRequestButton';
import PRIMARY from '~/constants/colors';
import './AgentProfileBannerMobile.module.scss';

class AgentProfileBannerMobile extends PureComponent {
    static propTypes = {
        consumerPage: PropTypes.object,
        contributorUploadImage: PropTypes.func,
        refetch: PropTypes.func,
    };

    static defaultProps = {
        consumerPage: () => {},
        contributorUploadImage: () => {},
        refetch: () => {},
    };

    state = {
        uploading: null,
        showAvatarModal: false,
    };

    customRequest = (key, { file, onSuccess, onError }) =>
        new Promise(resolve => {
            message.config({ top: 24 });

            const imageFormats = ['image/jpeg', 'image/png'];
            const exceededFileSize = key === 'profileImage' ? file.size > 2000000 : file.size > 3000000;

            if (!imageFormats.includes(file.type)) {
                return false;
            }
            if (exceededFileSize) {
                return false;
            }

            this.setState({ uploading: key });

            const { contributorUploadImage, consumerPage, refetch } = this.props;

            contributorUploadImage(consumerPage.id, key, file)
                .then(({ data: { contributorUploadImage } }) => {
                    onSuccess(contributorUploadImage, file);
                    refetch();
                    setTimeout(() => {
                        this.setState({ uploading: null });
                    }, 3000);
                    resolve(true);
                })
                .catch(() => {
                    onError();
                    this.setState({ uploading: null });
                    message.destroy();
                    message.error('Upload failed');
                });
        });

    handleUpload = (key, info) => {
        message.config({ top: 24 });
        const { fileList } = info;

        const imageFormats = ['image/jpeg', 'image/png'];
        const newFileList = fileList.filter(item => imageFormats.includes(item.type));
        const validFileSize =
            key === 'profile'
                ? fileList.filter(item => item.size <= 2000000)
                : fileList.filter(item => item.size <= 3000000);

        if (newFileList.length < 1) {
            message.destroy();
            message.error('You can only upload JPG or PNG file!');
            return false;
        }

        if (validFileSize.length < 1) {
            message.destroy();
            message.error(`Image must be smaller than ${key === 'profile' ? '(2MB)' : '(3MB)'}.`);
            return false;
        }
    };

    handleAvatarModal = () => {
        this.setState(({ showAvatarModal }) => ({ showAvatarModal: !showAvatarModal }));
    };

    render() {
        const { consumerPage, refetch, contributorUploadImage } = this.props;
        const { uploading, showAvatarModal } = this.state;

        const {
            owner: { firstName, lastName, initials, id, friendInfo },
            profileImage,
            coverImage,
            pageId,
            isPageOwner,
            isFollowed,
            isClaimed,
            id: consumerPageId,
            followCount,
        } = consumerPage || {};

        return (
            <div className="AgentProfileBannerMobile">
                <div
                    className="p-4 pt-6 z-0 HeaderBanner transition relative"
                    style={{ background: coverImage ? `url(${coverImage}) center top / cover no-repeat` : PRIMARY }}
                >
                    {!coverImage && isPageOwner && (
                        <div className="absolute z-20 pt-3 pl-3 pin sm:pt-0 sm:pl-0 sm:pr-4 sm:right-0 sm:inset-auto lg:inset-auto lg:w-full lg:text-center lg:pt-8 lg:pr-0">
                            <Upload
                                accept=".jpeg, .JPEG, .png, .PNG, .jpg, .JPG"
                                showUploadList={false}
                                customRequest={info => {
                                    this.customRequest('coverImage', info);
                                }}
                                onChange={info => {
                                    this.handleUpload('cover', info);
                                }}
                            >
                                <Button
                                    className="font-bold"
                                    type="primary"
                                    loading={uploading === 'coverImage'}
                                    icon={<UploadOutlined />}
                                >
                                    {uploading === 'coverImage' ? 'Uploading' : 'Upload Cover Photo'}
                                </Button>
                            </Upload>
                        </div>
                    )}

                    {coverImage && isPageOwner && (
                        <div className="absolute z-20 pt-3 pl-3 pin sm:pt-0 sm:pl-0 sm:pr-4 sm:right-0 sm:inset-auto">
                            <Upload
                                accept=".jpeg, .JPEG, .png, .PNG, .jpg, .JPG"
                                showUploadList={false}
                                customRequest={info => {
                                    this.customRequest('coverImage', info);
                                }}
                                onChange={info => {
                                    this.handleUpload('cover', info);
                                }}
                            >
                                <Button
                                    className="font-bold"
                                    type="primary"
                                    loading={uploading === 'coverImage'}
                                    icon={<EditOutlined />}
                                >
                                    {uploading === 'coverImage' ? 'Uploading' : 'Edit Cover Photo'}
                                </Button>
                            </Upload>
                        </div>
                    )}
                </div>
                <section className="-mt-16 flex-col flex items-center w-full justify-center border-b z-50">
                    {!profileImage && (
                        <div className="noAvatar">
                            <div className="noAvatarButtonWrapper">
                                {isPageOwner ? (
                                    <Button
                                        className="font-bold"
                                        size="small"
                                        type="primary"
                                        icon={<CameraOutlined />}
                                        onClick={this.handleAvatarModal}
                                    >
                                        Upload Picture
                                    </Button>
                                ) : (
                                    <span style={{ color: PRIMARY }} className="md:text-5xl text-3xl">
                                        {initials}
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                    {profileImage && (
                        <div
                            className="relative hasAvatar"
                            style={{ background: `#fff url(${profileImage}) center / cover no-repeat` }}
                        >
                            {isPageOwner && (
                                <div className="editProfileImageButton absolute z-10">
                                    <Button
                                        type="primary"
                                        shape="circle"
                                        icon={<EditOutlined />}
                                        onClick={this.handleAvatarModal}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    <ConsumerAvatarModal
                        handleAvatarModal={this.handleAvatarModal}
                        showAvatarModal={showAvatarModal}
                        contributorUploadImage={contributorUploadImage}
                        refetch={refetch}
                        consumerPage={consumerPage}
                    />
                    <div className="Mobile p-2 text-center ">
                        <div className="details">
                            <h2 className="text-overflow font-bold mb-1">
                                {firstName} {lastName}
                            </h2>
                            <div className="font-medium text-base">Real Estate Agent</div>
                            <h4 className="text-overflow py-2 mb-0 font-medium text-primary-500">@{pageId}</h4>
                            <h4 className="text-gray-600">
                                {followCount > 1 ? `${followCount} Followers` : `${followCount} Follower`}
                            </h4>
                        </div>
                        <div className="">
                            {!isPageOwner && (
                                <div className="my-4">
                                    <span className="mx-3">
                                        <FriendRequestButton
                                            isPageOwner={isPageOwner}
                                            pageOwnerId={id}
                                            friendInfo={friendInfo}
                                            refetch={refetch}
                                        />
                                    </span>
                                    <span className="mx-3">
                                        <FollowConsumerButton
                                            isPageOwner={isPageOwner}
                                            consumerId={id}
                                            isFollowed={isFollowed}
                                            refetch={refetch}
                                        />
                                    </span>
                                    <span className="mx-3">
                                        <ClaimAgentSocialButton
                                            isPageOwner={isPageOwner}
                                            consumerId={id}
                                            consumerPageId={consumerPageId}
                                            isClaimed={isClaimed}
                                            refetch={refetch}
                                        />
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const CONTRIBUTOR_UPLOAD_IMAGE_MUTATION = gql`
    mutation contributorUploadImage($id: ID!, $type: String, $file: Upload!) {
        contributorUploadImage(id: $id, type: $type, file: $file)
    }
`;

export default compose(
    graphql(CONTRIBUTOR_UPLOAD_IMAGE_MUTATION, {
        props: ({ mutate }) => ({
            contributorUploadImage: (id, type, file) =>
                mutate({
                    variables: {
                        id,
                        type,
                        file,
                    },
                }),
        }),
    })
)(AgentProfileBannerMobile);
