import { useMutation } from '@apollo/client';
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CameraOutlined, LoadingOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import ImagesSliderModal from '../ImagesSliderModal';
import { ContributorUploadImageMutation } from './Operations.graphql';
import PRIMARY from '~/constants/colors';
import styles from './BusinessUploadCoverPhoto.module.scss';

const BusinessUploadCoverPhoto = ({ consumerPageId, coverImage, isPageOwner, refetch }) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [contributorUploadImage, { loading }] = useMutation(ContributorUploadImageMutation);

    const handleImagePreview = (url) => {
        if (url) {
            setPreviewVisible(true);
        }
    };

    const handleCloseImagePreview = () => {
        setPreviewVisible(false);
    };

    const customRequest = ({ file, onSuccess, onError }) =>
        new Promise((resolve) => {
            message.config({ top: 24 });
            const imageFormats = ['image/jpeg', 'image/png'];
            if (!imageFormats.includes(file.type)) {
                return false;
            }
            if (file.size > 3000000) {
                return false;
            }

            contributorUploadImage({
                variables: {
                    id: consumerPageId,
                    type: 'coverImage',
                    file,
                },
            })
                .then(({ data: { contributorUploadImage } }) => {
                    onSuccess(contributorUploadImage, file);
                    message.destroy();
                    message.loading('Setting cover photo...');
                    refetch();
                    resolve(true);
                })
                .catch(() => {
                    onError();
                    message.destroy();
                    message.error('Upload failed');
                });
        });

    const handleUpload = (info) => {
        message.config({ top: 24 });
        const { fileList } = info;

        const imageFormats = ['image/jpeg', 'image/png'];
        const validFileList = fileList.filter((item) => imageFormats.includes(item.type));
        const validFileSize = fileList.filter((item) => item.size <= 3000000);

        if (validFileList.length < 1) {
            message.destroy();
            message.error('You can only upload JPG or PNG file!');
            return false;
        }

        if (validFileSize.length < 1) {
            message.destroy();
            message.error('The specified file is larger than the maximum supported file size (3MB).');
            return false;
        }
    };

    const businessUploadCoverButtonStyle = !coverImage
        ? styles.businessUploadCoverButton
        : styles.businessUploadCoverButtonWithImage;

    return (
        <div className="relative">
            <div
                onClick={() => handleImagePreview(coverImage)}
                className={`${styles.businessCoverBox} ${!coverImage ? styles.noImage : 'cursor-pointer'}`}
                style={{ background: coverImage ? `url(${coverImage}) center top / cover no-repeat` : PRIMARY }}
            />

            <div className={`${businessUploadCoverButtonStyle} ${isPageOwner ? 'block' : 'hidden'} absolute z-20`}>
                <Upload
                    accept=".jpeg, .JPEG, .png, .PNG, .jpg, .JPG"
                    showUploadList={false}
                    customRequest={(info) => {
                        customRequest(info);
                    }}
                    onChange={(info) => {
                        handleUpload(info);
                    }}
                >
                    {coverImage ? (
                        <div
                            style={{
                                backgroundColor: PRIMARY,
                                lineHeight: '24px',
                            }}
                            className="px-2 py-1 text-white"
                        >
                            {loading ? (
                                <LoadingOutlined style={{ fontSize: 16 }} />
                            ) : (
                                <EditOutlined style={{ fontSize: 16 }} />
                            )}
                        </div>
                    ) : (
                        <Button loading={loading} icon={<CameraOutlined />}>
                            {loading ? 'Uploading' : 'Upload Photo'}
                        </Button>
                    )}
                </Upload>
            </div>

            <ImagesSliderModal
                visible={previewVisible}
                images={coverImage ? [{ url: coverImage }] : []}
                onClose={handleCloseImagePreview}
            />
        </div>
    );
};

BusinessUploadCoverPhoto.propTypes = {
    consumerPageId: PropTypes.string,
    coverImage: PropTypes.string,
    isPageOwner: PropTypes.bool,
    data: PropTypes.object,
    refetch: PropTypes.func,
};

BusinessUploadCoverPhoto.defaultProps = {
    consumerPageId: null,
    coverImage: null,
    isPageOwner: false,
    data: {},
    refetch: () => {},
};

export default BusinessUploadCoverPhoto;
