import React from 'react';
import Link from 'next/link';
import Image from 'next/legacy/image';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import image404 from './404.png';
import styles from './NotFound.module.scss';

const NotFound = () => (
    <div className="full-page flex items-center justify-center bg-white">
        <div className={styles.NotFound}>
            <div className="items-center justify-center lg:flex">
                <div className="flex items-center justify-center">
                    <Image src={image404} alt="Not Found" width={572} height={366} />
                </div>
                <div className={`${styles['banner-text']} flex-1`}>
                    <h1>Ooops!</h1>
                    <p>
                        Sorry but the page you are looking for does not exist, have been removed, name changed or is
                        temporarily unavailable.
                    </p>
                    <Link href="/" as="/">
                        <FA icon={faArrowLeft} className="mr-2" />
                        Back to Home page
                    </Link>
                </div>
            </div>
            <div className={`${styles['helpful-links']} text-center`}>
                <p className="py-4 text-base">Here are some helpful links instead:</p>
                <div className="flex flex-wrap items-center justify-center space-x-4">
                    <div className={styles['helpful-links-box']}>
                        <Link href="/suburb-profile" as="/suburb-profile" className="underline">
                            Explore Suburbs
                        </Link>
                    </div>
                    <div className={styles['helpful-links-box']}>
                        <Link href="/buy/search" as="/buy/search" className="underline">
                            Property for sale
                        </Link>
                    </div>
                    <div className={styles['helpful-links-box']}>
                        <Link href="/rent/search" as="/rent/search" className="underline">
                            Property for rent
                        </Link>
                    </div>
                    <div className={styles['helpful-links-box']}>
                        <Link href="/sold/search" as="/sold/search" className="underline">
                            Sold Properties
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default NotFound;
