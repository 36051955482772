/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import { Col, Row } from 'antd';
import ConsumerPageSeoHeader from '~/components/ConsumerPageSeoHeader';
import ConsumerProfileAbout from '~/components/ConsumerProfileAbout';
import ConsumerProfileBanner from '~/components/ConsumerProfileBanner';
import ConsumerProfileFollowersList from '~/components/ConsumerProfileFollowersList';
import ConsumerProfileFollowingContributorList from '~/components/ConsumerProfileFollowingContributorList';
import ConsumerProfileFollowingPageList from '~/components/ConsumerProfileFollowingPageList';
// import ConsumerProfileFollowingAgencyList from '../../components/ConsumerProfileFollowingAgencyList';
// import ConsumerProfileFollowingAgentList from '../../components/ConsumerProfileFollowingAgentList';
import ConsumerProfileFollowingPropertyList from '~/components/ConsumerProfileFollowingPropertyList';
import ConsumerProfileFollowingSuburbList from '~/components/ConsumerProfileFollowingSuburbList';
import ConsumerProfileFriendList from '~/components/ConsumerProfileFriendList';
import ConsumerProfilePostForm from '~/components/ConsumerProfilePostForm';
import ConsumerProfilePosts from '~/components/ConsumerProfilePosts';
import InviteAFriendButton from '~/components/InviteAFriendButton';
import PublicLayout from '~/layouts/PublicLayout';
import styles from './ConsumerProfilePage.module.scss';

const ConsumerProfilePage = ({ consumerPage, refetch, isAuthenticated }) => {
    const { pageId, isPageOwner, coverImage, profileImage } = consumerPage || {};

    return (
        <PublicLayout showFooter={false}>
            <div className="bg-gray-200" style={{ minHeight: '210vh' }}>
                <ConsumerPageSeoHeader consumerPage={consumerPage} />
                <div className={styles.ConsumerProfilePage}>
                    <main className="bg-white sm:bg-transparent">
                        <ConsumerProfileBanner
                            consumerPage={consumerPage}
                            refetch={refetch}
                            isPageOwner={isPageOwner}
                            coverImage={coverImage}
                            profileImage={profileImage}
                        />
                    </main>
                    <Row gutter={24} className="h-full" justify="center">
                        <Col xs={0} sm={0} md={9} lg={9} xl={9}>
                            <div className="pt-3">
                                <InviteAFriendButton />
                            </div>
                            <ConsumerProfileAbout consumerPage={consumerPage} refetch={refetch} />
                            <div className="pt-3">
                                <ConsumerProfileFollowersList pageId={pageId} editable={isPageOwner} />
                            </div>
                            <ConsumerProfileFriendList pageId={pageId} editable={isPageOwner} />
                            <div className="pt-3">
                                <ConsumerProfileFollowingContributorList pageId={pageId} editable={isPageOwner} />
                            </div>
                            <div className="pt-3">
                                <ConsumerProfileFollowingPageList pageId={pageId} editable={isPageOwner} />
                            </div>
                            {/* <div className="pt-3">
                                <ConsumerProfileFollowingAgencyList pageId={pageId} editable={isPageOwner} />
                            </div>
                            <div className="pt-3">
                                <ConsumerProfileFollowingAgentList pageId={pageId} editable={isPageOwner} />
                            </div> */}
                            <div className="pt-3">
                                <ConsumerProfileFollowingPropertyList pageId={pageId} editable={isPageOwner} />
                            </div>
                            <div className="pt-3">
                                <ConsumerProfileFollowingSuburbList pageId={pageId} editable={isPageOwner} />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                            {isPageOwner && <ConsumerProfilePostForm pageId={pageId} editable={isPageOwner} />}
                            <ConsumerProfilePosts pageId={pageId} editable={isPageOwner} />
                        </Col>
                    </Row>
                </div>
            </div>
        </PublicLayout>
    );
};

ConsumerProfilePage.propTypes = {
    consumerPage: PropTypes.object,
    refetch: PropTypes.func,
    isAuthenticated: PropTypes.bool,
};

ConsumerProfilePage.defaultProps = {
    consumerPage: {},
    refetch: () => {},
    isAuthenticated: false,
};

export default withRouter(ConsumerProfilePage);
