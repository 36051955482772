import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Tabs } from 'antd';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/pro-light-svg-icons';
import ConsumerProfileFollowersList from '~/components/ConsumerProfileFollowersList';
import ConsumerProfileFollowingContributorList from '~/components/ConsumerProfileFollowingContributorList';
import './AgentAbout.module.scss';

dayjs.extend(localizedFormat);

const AgentAbout = (props) => {
    const { page = {} } = props;
    const { phoneNo, address, instagram, facebook, owner, about, agent, createdAt, pageId, isPageOwner } = page;

    const { agencyName, email } = owner || {};

    const {
        position,
        agency: { url },
    } = agent;

    return (
        <main className="AgentAbout bg-white">
            <Tabs tabBarStyle={{ padding: '0 20px' }} defaultActiveKey="info" size="large" className="property-tabs">
                <Tabs.TabPane tab="Info" key="info" style={{ backgroundColor: '#fff' }}>
                    <div className="p-6">
                        <section className="border-b pb-3 sm:border-none">
                            <h3 className="mb-2 font-medium text-gray-600">Overview</h3>
                            <div className="pl-3 text-base">{about}</div>
                        </section>
                        <section className="flex flex-wrap">
                            <div className="w-full border-b pb-3 sm:w-auto sm:flex-1 sm:border-none">
                                <h3 className="my-2 font-medium text-gray-600">Profile</h3>
                                <div className="mb-2 pl-3 text-sm">
                                    <span className="mr-2">Agency</span>
                                    {agencyName}
                                </div>
                                <div className="mb-2 pl-3 text-sm">
                                    <span className="mr-2">Position</span>
                                    {position}
                                </div>
                                {url && (
                                    <div className="mb-2 pl-3 text-sm">
                                        <span className="mr-2">Agency website</span>
                                        <a href={url} target="_blank" rel="noopener noreferrer">
                                            {url}
                                        </a>
                                    </div>
                                )}
                                <div className="mb-2 pl-3 text-sm">
                                    <span className="mr-2">Address</span>
                                    <a
                                        href={`//maps.google.com/?q=${address}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {address}
                                    </a>
                                </div>
                            </div>
                            <div className="w-full sm:w-auto sm:flex-1">
                                <div className="contactInfo border-b pb-3 sm:border-none">
                                    <h3 className="my-2 font-medium text-gray-600">Contact Info</h3>
                                    {phoneNo && (
                                        <div className="mb-2 pl-3 text-sm">
                                            <FA size="lg" icon={faPhone} className="mr-2 text-primary-500" />
                                            <a href={`tel:${phoneNo}`}>{phoneNo}</a>
                                        </div>
                                    )}
                                    {email && (
                                        <div className="mb-2 pl-3 text-sm">
                                            <FA size="lg" icon={faEnvelope} className="mr-2 text-primary-500" />
                                            <a className="text-black" href={`mailto:${email}`}>
                                                {email}
                                            </a>
                                        </div>
                                    )}
                                </div>
                                <div className="socials border-b pb-3 sm:border-none">
                                    <h3 className="my-2 font-medium text-gray-600">Social Profiles</h3>
                                    <div className="flex flex-wrap">
                                        {facebook && (
                                            <div className="my-2 pl-3">
                                                <a href={facebook} target="_blank" rel="noopener noreferrer">
                                                    <FA icon={faFacebookF} className="mr-2 text-2xl text-primary-500" />
                                                </a>
                                            </div>
                                        )}
                                        {instagram && (
                                            <div className="my-2 pl-3">
                                                <a href={instagram} target="_blank" rel="noopener noreferrer">
                                                    <FA icon={faInstagram} className="mr-2 text-2xl text-primary-500" />
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="joinedDate">
                                    <h3 className="my-2 font-medium text-gray-600">Date Joined</h3>
                                    <span className="text-sm">{dayjs(createdAt).format('l')}</span>
                                </div>
                            </div>
                        </section>
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Followers" key="follower" style={{ backgroundColor: '#fff' }}>
                    <div className="p-6">
                        <ConsumerProfileFollowersList pageId={pageId} editable={isPageOwner} />
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Following" key="following" style={{ backgroundColor: '#fff' }}>
                    <div className="p-6">
                        <ConsumerProfileFollowingContributorList pageId={pageId} editable={isPageOwner} />
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </main>
    );
};

AgentAbout.propTypes = {
    page: PropTypes.shape({
        contact: PropTypes.string,
        phoneNo: PropTypes.string,
        address: PropTypes.string,
        instagram: PropTypes.string,
        facebook: PropTypes.string,
        website: PropTypes.string,
        owner: PropTypes.object,
        about: PropTypes.string,
        agent: PropTypes.object,
        createdAt: PropTypes.string,
        refetch: PropTypes.func,
    }),
};

AgentAbout.defaultProps = { page: {} };

export default AgentAbout;
