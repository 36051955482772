import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Button, Col, List, Row } from 'antd';

import Error from '~/components/Error';
import Loading from '~/components/Loading';
import LoadingDots from '~/components/LoadingDots';
import PropertyCard from '~/components/PropertyCard';

import UserQuery from '~/queries/AgentQuery.graphql';

const AgentPropertyInfiniteList = (props) => {
    const { id, suburb, group, sorting } = props;

    const { data, loading, error, fetchMore } = useQuery(UserQuery, {
        variables: {
            id,
            group,
            filter: {
                first: 12,
                isActive: true,
                group,
                suburb,
                sorting,
            },
        },
    });

    const [loadingMore, setLoadingMore] = useState(false);

    const { agent } = data || {};

    const handleLoadMore = async () => {
        setLoadingMore(true);

        await fetchMore({
            variables: {
                filter: {
                    after: agent.propertiesConnection.pageInfo.endCursor,
                    first: 12,
                    isActive: true,
                    group,
                    suburb,
                    sorting,
                },
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const { nodes } = fetchMoreResult.agent.propertiesConnection;
                return nodes.length
                    ? {
                          agent: {
                              ...fetchMoreResult.agent,
                              propertiesConnection: {
                                  ...fetchMoreResult.agent.propertiesConnection,
                                  nodes: [...previousResult.agent.propertiesConnection.nodes, ...nodes],
                              },
                          },
                      }
                    : previousResult;
            },
        });
        setLoadingMore(false);
    };

    if (loading) {
        return (
            <div className="loading-container relative h-64">
                <Loading className="center-align" />
            </div>
        );
    }
    if (error) return <Error error={error} />;

    const { propertiesConnection } = agent;

    const showLoadingMore = propertiesConnection ? propertiesConnection.pageInfo.hasNextPage : false;
    const properties = propertiesConnection ? propertiesConnection.nodes : [];

    const loadMore =
        !loading && showLoadingMore ? (
            <div className="loading-container">
                {loadingMore && <LoadingDots />}
                {!loadingMore && (
                    <Button
                        ghost
                        type="primary"
                        className="w-full max-w-xs rounded "
                        size="large"
                        onClick={handleLoadMore}
                    >
                        Load More
                    </Button>
                )}
            </div>
        ) : null;

    return (
        <div className="AgentPropertyInfiniteList">
            {properties.length > 0 ? (
                <Row className="py-8" justify="center">
                    <Col xs={24} sm={22} md={22} lg={22} xl={22} xxl={16}>
                        <List
                            loadMore={loadMore}
                            className="search-results"
                            locale={{ emptyText: null }}
                            key="search-results"
                        >
                            <Row justify="center" gutter={16}>
                                {properties.map((item) => (
                                    <Col
                                        xs={23}
                                        sm={12}
                                        md={12}
                                        lg={8}
                                        xl={6}
                                        xxl={6}
                                        className="my-2 flex items-stretch"
                                        key={item.id}
                                    >
                                        <PropertyCard property={item} carouselImageHeight="100%" />
                                    </Col>
                                ))}
                            </Row>
                        </List>
                    </Col>
                </Row>
            ) : (
                <div className="py-8 text-center">
                    {group === 'BUY' && 'No Properties For Sale'}
                    {group === 'SOLD' && 'No Sold Properties'}
                    {group === 'RENT' && 'No Properties For Rent'}
                </div>
            )}
        </div>
    );
};

AgentPropertyInfiniteList.propTypes = {
    id: PropTypes.string,
    group: PropTypes.string,
    suburb: PropTypes.string,
    sorting: PropTypes.string,
};

AgentPropertyInfiniteList.defaultProps = { id: null, group: null, suburb: null, sorting: null };

export default AgentPropertyInfiniteList;
