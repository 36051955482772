/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { Select } from 'antd';
import CategoriesQuery from '../../queries/CategoriesQuery.graphql';
import './CategorySelect.module.scss';

class CategorySelect extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        data: PropTypes.object,
        value: PropTypes.array,
    };

    static defaultProps = {
        onChange: () => {},
        data: {},
        value: [],
    };

    constructor(props) {
        super(props);
        const { value } = this.props;
        this.state = { selected: this.extractCode(value) };
    }

    componentWillReceiveProps(nextProps) {
        // Should be a controlled component.
        if ('value' in nextProps) {
            const { value } = nextProps;
            this.setState({ selected: this.extractCode(value) });
        }
    }

    extractCode = values => (values ? values.map(item => item.name) : []);

    handleChange = value => {
        const { data } = this.props;
        const newValue = [value];
        const selectedData = value
            ? newValue.map(name => {
                  const categoryData = data.categories.find(crm => crm.name === name);
                  return {
                      id: categoryData.id,
                      name,
                  };
              })
            : undefined;

        if (!('value' in this.props)) {
            this.setState({ selected: newValue });
        }

        this.triggerChange(selectedData);
    };

    triggerChange = changedValue => {
        const { onChange } = this.props;
        // Should provide an event to pass value to Form.
        onChange(changedValue);
    };

    render() {
        const { selected } = this.state;
        const { data } = this.props;
        const categories = data.categories ? data.categories : [];

        return (
            <Select
                size="large"
                allowClear
                placeholder="Select category"
                onChange={this.handleChange}
                value={selected}
                className="w-full crm-select"
            >
                {categories.map(({ name }) => (
                    <Select.Option key={name}>{name}</Select.Option>
                ))}
            </Select>
        );
    }
}

export default graphql(CategoriesQuery)(CategorySelect);
