import React from 'react';
import PropertyCardSkeleton from '~/components/PropertyCardSkeleton';

const AgentPropertiesSectionSkeleton = () => {
    const makeSkeleton = count => {
        const skeletons = [];
        for (let i = 0; i < count; i += 1) {
            skeletons.push(
                <div className="sm:w-1/2 w-full p-2" key={i}>
                    <PropertyCardSkeleton />
                </div>
            );
        }

        return skeletons;
    };

    return <main className="AgentPropertiesSectionSkeleton flex flex-wrap">{makeSkeleton(2)}</main>;
};

export default AgentPropertiesSectionSkeleton;
