/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import ImagesSliderModal from '~/components/ImagesSliderModal';
import PRIMARY from '~/constants/colors';
import styles from './BusinessPagePhotos.module.scss';

const BusinessPagePhotos = ({ consumerPage }) => {
    const [carouselVisible, setCarouselVisible] = useState(false);
    const [carouselImages, setCarouselImages] = useState(null);
    const [initialSlide, setInitialSlide] = useState(null);
    const [photosToShow, setPhotosToShow] = useState(12);

    const handleImageCarousel = (images, index) => {
        setCarouselVisible(true);
        setCarouselImages(images);
        setInitialSlide(index);
    };

    const onImagesModalClose = () => {
        setCarouselVisible(false);
    };

    const showMorePhotos = () => {
        setPhotosToShow(consumerPage.images.length);
    };

    return (
        <>
            <div className="rounded border border-solid border-gray-300 bg-white">
                <div className="px-4 py-3 text-base font-bold text-gray-900">All Photos</div>
                {!isEmpty(consumerPage.images) ? (
                    <>
                        <div className={`${styles.photoPageContainer} flex flex-wrap`}>
                            {consumerPage.images.slice(0, photosToShow).map((image, index) => (
                                <div
                                    className={`${styles.photoPageBox} mr-1 mb-1 shrink-0 grow cursor-pointer`}
                                    style={{ backgroundImage: `url(${image.url})` }}
                                    key={image.url}
                                    onClick={() => handleImageCarousel(consumerPage.images, index)}
                                />
                            ))}
                        </div>
                        {consumerPage.images.length > 12 && photosToShow !== consumerPage.images.length && (
                            <div
                                className="cursor-pointer px-4 py-3 text-center font-semibold"
                                style={{ color: PRIMARY }}
                                onClick={showMorePhotos}
                            >
                                Show more
                            </div>
                        )}
                    </>
                ) : (
                    <div className="my-20 text-center">No photos yet.</div>
                )}
            </div>

            <ImagesSliderModal
                visible={carouselVisible}
                images={carouselImages}
                initialSlide={initialSlide}
                onClose={onImagesModalClose}
            />
        </>
    );
};

BusinessPagePhotos.propTypes = { consumerPage: PropTypes.object };

BusinessPagePhotos.defaultProps = { consumerPage: {} };

export default BusinessPagePhotos;
