import { useMutation } from '@apollo/client';
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, Modal } from 'antd';
import { winWidth } from '../../utils/windowDimensions';
import { ConsumerSendEmailMutation } from './Operations.graphql';

const BusinessSendEmailModal = ({
    showEmailModal,
    toggleSendEmailModal,
    consumerPageEmail,
    consumerPageName,
    form: { validateFields, resetFields, getFieldDecorator },
}) => {
    const [formErrorCode, setFormErrorCode] = useState(null);

    const [consumerSendEmail, { loading }] = useMutation(ConsumerSendEmailMutation);

    const getErrorMessage = () => {
        let errorMessage = null;

        switch (formErrorCode) {
            case 'SERVER_ERROR':
                errorMessage = 'There was a problem sending email.';
                break;
            default:
                errorMessage = 'Unable to resolve sending an email.';
                break;
        }
        return errorMessage;
    };

    const handleSubmit = e => {
        message.config({ top: 24 });
        e.preventDefault();

        setFormErrorCode(null);

        validateFields((err, values) => {
            if (!err) {
                const newValues = {
                    ...values,
                    businessEmail: consumerPageEmail,
                    businessName: consumerPageName,
                };

                consumerSendEmail({ variables: { input: newValues } })
                    .then(() => {
                        message.destroy();
                        message.success('Email sent');

                        resetFields();
                        toggleSendEmailModal();
                    })
                    .catch(error => {
                        const errors = error.graphQLErrors || [];
                        const formErrCode = errors.length > 0 ? errors[0].extensions.code : null;

                        setFormErrorCode(formErrCode);
                        message.destroy();
                        message.error(getErrorMessage());
                    });
            }
        });
    };

    const handleCloseForm = () => {
        resetFields();
        toggleSendEmailModal();
    };

    return (
        <Modal
            title="Send us an email"
            closable={false}
            okText="Send"
            onCancel={handleCloseForm}
            visible={showEmailModal}
            footer={null}
        >
            <Form onSubmit={handleSubmit}>
                <Form.Item>
                    {getFieldDecorator('message', {
                        validateFirst: true,
                        rules: [
                            {
                                required: true,
                                message: 'Please enter your message',
                            },
                        ],
                    })(<Input.TextArea autoSize={{ minRows: 6 }} placeholder="Enter your message" />)}
                </Form.Item>
                <Form.Item className="text-right mb-0">
                    <Button size={winWidth > 768 ? 'large' : 'default'} className="mr-2" onClick={handleCloseForm}>
                        Cancel
                    </Button>
                    <Button
                        size={winWidth > 768 ? 'large' : 'default'}
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Send
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

BusinessSendEmailModal.propTypes = {
    form: PropTypes.object,
    toggleSendEmailModal: PropTypes.func,
    showEmailModal: PropTypes.bool,
    consumerPageEmail: PropTypes.string,
    consumerPageName: PropTypes.string,
};

BusinessSendEmailModal.defaultProps = {
    form: {},
    toggleSendEmailModal: () => {},
    showEmailModal: false,
    consumerPageEmail: null,
    consumerPageName: null,
};

export default Form.create()(BusinessSendEmailModal);
