/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Affix, Col, Row } from 'antd';
import BusinessPosts from '~/components/BusinessPosts';
import BusinessSocial from '~/components/BusinessSocial';

const BusinessPagePosts = ({ isAuthenticated, consumerPage, refetch }) => (
    <Row
        justify="center"
        gutter={{
            xs: 0,
            md: 16,
        }}
    >
        <Col xs={24} sm={24} md={24} lg={24} xl={15} xxl={15} className="mb-2 self-start">
            <BusinessPosts
                contributorId={consumerPage.contributorId}
                consumerPageId={consumerPage.id}
                consumerPage={consumerPage}
                isAuthenticated={isAuthenticated}
                pageId={consumerPage.pageId}
            />
        </Col>
        <Col xs={0} sm={0} md={0} lg={0} xl={9} xxl={9} className="justify-between">
            <Affix offsetTop={30}>
                <div>
                    <BusinessSocial consumerPage={consumerPage} refetch={refetch} isAuthenticated={isAuthenticated} />
                </div>
            </Affix>
        </Col>
    </Row>
);

BusinessPagePosts.propTypes = {
    consumerPage: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    refetch: PropTypes.func,
};

BusinessPagePosts.defaultProps = {
    consumerPage: {},
    isAuthenticated: false,
    refetch: () => {},
};

export default BusinessPagePosts;
