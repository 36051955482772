import { gql } from '@apollo/client';
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { EnvironmentFilled } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, Radio, Select, Spin } from 'antd';
import { faCube, faImages, faPenSquare, faVideo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import getUrls from 'get-urls';
import compose from 'lodash.flowright';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { addRecordToCache } from '~/utils/graphql';
import { FeedEventsNames, withEmitterContext } from '~/context/emitter-context';
import LocationsConnectionQuery from '~/queries/LocationsConnectionQuery.graphql';
import ConsumerPostImageForm from '~/components/ConsumerPostImageForm';
import ConsumerPostMeta from '~/components/ConsumerPostMeta';
import ConsumerProfilePostAvatar from '~/components/ConsumerProfilePostAvatar';
import ConsumerPageEventsConnection from '~/components/ConsumerProfilePosts/Operations.graphql';
import PRIMARY from '~/constants/colors';
import { SiteMetaQuery } from './Operations.graphql';
import './ConsumerProfilePostForm.module.scss';

const { Option } = Select;

class ConsumerProfilePostForm extends Component {
    static propTypes = {
        data: PropTypes.object,
        form: PropTypes.object,
        client: PropTypes.object,
        createConsumerPagePost: PropTypes.func,
        pageId: PropTypes.string,
        editable: PropTypes.bool,
    };

    static defaultProps = {
        data: {},
        form: {},
        client: {},
        createConsumerPagePost: () => {},
        pageId: null,
        editable: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            placeholder: 'Share your contribution',
            isLoading: false,
            type: 'review',
            isPasting: true,
            metas: null,
            formShadow: 'sm:shadow-sm',
            postButtonDisplay: 'hideBtn',
            postButtonDisabled: true,
            postText: '',
            fileList: [],
            imageKeys: [],
            showCheckInBox: false,
            isSearching: false,
            dataSource: [],
        };

        this.handleSearch = debounce(this.handleSearch, 500);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.onClickOutsidePostForm);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.onClickOutsidePostForm);
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    handleChange = (e) => {
        const { metas, postText, imageKeys } = this.state;
        const { value } = e.target;
        const placeholder =
            value === 'review' || value === 'image' ? 'Share your contribution' : `Add link for your ${value}`;

        let postButtonDisabled;
        switch (value) {
            case 'review':
                postButtonDisabled = isEmpty(metas) && isEmpty(trim(postText));
                break;
            case 'image':
                postButtonDisabled = isEmpty(imageKeys) && isEmpty(trim(postText));
                break;
            default:
                postButtonDisabled = true;
                break;
        }

        this.setState({
            placeholder,
            type: value,
            postButtonDisabled,
        });
    };

    onPaste = () => {
        this.setState({ isPasting: true });
    };

    onRemoveMeta = () => {
        this.setState({ metas: null }, this.onChangeMeta);
    };

    onRemoveImage = () => {
        this.setState(({ metas }) => ({ metas: { ...metas, image: null } }));
    };

    onChangeMeta = () => {
        const { metas, postText } = this.state;
        this.setState({ postButtonDisabled: isEmpty(metas) && isEmpty(trim(postText)) });
    };

    onChange = (e) => {
        const { isPasting, type, metas, imageKeys } = this.state;

        if (isPasting) {
            const urls = getUrls(e.target.value);
            if (urls.size > 0) {
                const setIter = urls.values();

                const url = setIter.next().value;
                this.queryMetaTags(url);

                this.setState({ isPasting: false });
            }
        }

        let postButtonDisabled;
        switch (type) {
            case 'review':
                postButtonDisabled = isEmpty(metas) && isEmpty(trim(e.target.value));
                break;
            case 'image':
                postButtonDisabled = isEmpty(imageKeys) && isEmpty(trim(e.target.value));
                break;
            default:
                postButtonDisabled = true;
                break;
        }

        this.setState({
            postButtonDisabled,
            postText: e.target.value,
        });
    };

    onClickPostForm = () => {
        this.setState({
            formShadow: 'sm:shadow-md',
            postButtonDisplay: 'showBtn',
        });
    };

    onClickOutsidePostForm = (event) => {
        if (this.wrapperRef && this.wrapperRef.contains(event.target)) {
            return;
        }
        this.setState({
            formShadow: 'sm:shadow-sm',
            postButtonDisplay: 'hideBtn',
        });
    };

    onUploadFocus = () => {
        this.setState({ postButtonDisplay: 'showBtn' });
    };

    onUploadImage = (value) => {
        const {
            form: { getFieldValue },
        } = this.props;
        const { postText } = this.state;

        this.setState({
            postButtonDisabled: isEmpty(postText) && value,
            imageKeys: getFieldValue('images'),
        });
    };

    onStoreImageKeys = (fileList) => {
        this.setState({ fileList });
    };

    queryMetaTags = async (url) => {
        const { client } = this.props;

        try {
            const {
                data: { siteMeta },
            } = await client.query({
                query: SiteMetaQuery,
                variables: { url },
            });
            this.setState({ metas: siteMeta || null });
        } catch (error) {
            console.log(error);
        }
    };

    handleCheckInBox = () => {
        this.setState((prevState) => ({ showCheckInBox: !prevState.showCheckInBox }));
    };

    handleSearch = async (value) => {
        const { client } = this.props;
        const keyword = value;

        this.setState({ isSearching: true });

        try {
            const {
                data: { locationsConnection },
            } = await client.query({
                query: LocationsConnectionQuery,
                variables: {
                    locationsFilter: {
                        first: 10,
                        keyword,
                        type: 'SUBURB',
                    },
                },
            });
            this.setState({
                isSearching: false,
                dataSource: locationsConnection.nodes || [],
            });
        } catch (error) {
            console.log(error);
        }
    };

    handleSubmit = (e) => {
        message.config({ top: 24 });
        e.preventDefault();

        const {
            form: { validateFieldsAndScroll, resetFields },
            createConsumerPagePost,
            pageId,
        } = this.props;

        const { metas, imageKeys, type } = this.state;

        this.setState({ isLoading: true });

        validateFieldsAndScroll((err, values) => {
            if (!err) {
                const postInputValues = { ...values };

                if (metas) {
                    Object.assign(postInputValues, {
                        metaHost: metas.host || null,
                        metaUrl: metas.url || null,
                        metaDescription: metas.description || null,
                        metaImage: metas.image || null,
                        metaTitle: metas.title || null,
                    });
                } else {
                    Object.assign(postInputValues, {
                        metaHost: null,
                        metaUrl: null,
                        metaDescription: null,
                        metaImage: null,
                        metaTitle: null,
                    });
                }

                if (type === 'image') {
                    if (isEmpty(values.images) && !isEmpty(imageKeys)) {
                        Object.assign(postInputValues, { images: imageKeys });
                    }
                }

                createConsumerPagePost(postInputValues, pageId)
                    .then(() => {
                        message.destroy();
                        message.success('Post successfully posted');
                        this.setState({ metas: null });
                        resetFields();
                    })
                    .catch((error) => {
                        message.destroy();
                        message.error('Something went wrong');
                        console.log(error);
                    })
                    .finally(() => {
                        this.setState({
                            fileList: [],
                            postText: '',
                            imageKeys: [],
                            type: 'review',
                            isLoading: false,
                            postButtonDisabled: true,
                            postButtonDisplay: 'hideBtn',
                            showCheckInBox: false,
                        });
                    });
            }
        });
    };

    renderOptions = (dataSource = []) => {
        const filteredResult = dataSource.filter((suburb) => suburb !== null);

        return filteredResult.map((suburb) => (
            <Option key={suburb.id} className="py-3" value={suburb.full}>
                <span className="text-base">{suburb.full}</span>
            </Option>
        ));
    };

    render() {
        const {
            placeholder,
            isLoading,
            type,
            metas,
            formShadow,
            postButtonDisplay,
            postButtonDisabled,
            fileList,
            imageKeys,
            showCheckInBox,
            isSearching,
            dataSource,
        } = this.state;
        const {
            form: { getFieldDecorator },
            editable,
        } = this.props;
        const checkInBoxStyle = showCheckInBox ? 'showDropDown' : 'hideDropDown';

        return (
            <main
                ref={this.setWrapperRef}
                className={`p-4 ${formShadow} consumer-content-form mb-4 mt-3 bg-white transition sm:rounded-lg`}
                onClick={this.onClickPostForm}
            >
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item className="content-type mb-0">
                        {getFieldDecorator('type', { initialValue: type })(
                            <Radio.Group
                                buttonStyle="solid"
                                className="content-type-radio"
                                onChange={this.handleChange}
                            >
                                <Radio.Button value="review" className="border-none">
                                    <FA icon={faPenSquare} />
                                </Radio.Button>
                                <Radio.Button value="image" className="border-none">
                                    <FA icon={faImages} />
                                </Radio.Button>
                                <Radio.Button value="video" className="border-none" disabled title="Coming">
                                    <FA icon={faVideo} />
                                </Radio.Button>
                                <Radio.Button value="3d" className="border-none" disabled title="Coming">
                                    <FA icon={faCube} />
                                </Radio.Button>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    {type !== 'image' ? (
                        <>
                            <Form.Item className="mb-0">
                                <div className="flex space-x-2 rounded rounded-tl-none bg-white">
                                    {editable && (
                                        <div className="pt-2 pl-2">
                                            <ConsumerProfilePostAvatar />
                                        </div>
                                    )}
                                    {getFieldDecorator('content')(
                                        <Input.TextArea
                                            onChange={this.onChange}
                                            onPaste={this.onPaste}
                                            className="content-textarea rounded-tl-none border transition"
                                            placeholder={placeholder}
                                            autoSize={{ minRows: 3 }}
                                        />
                                    )}
                                </div>
                            </Form.Item>
                            {metas && (
                                <ConsumerPostMeta
                                    {...metas}
                                    editable
                                    onRemoveMeta={this.onRemoveMeta}
                                    onRemoveImage={this.onRemoveImage}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <Form.Item className="mb-0">
                                <div className="flex rounded-tr bg-white">
                                    {editable && (
                                        <div className="pt-2 pl-2">
                                            <ConsumerProfilePostAvatar />
                                        </div>
                                    )}
                                    <div className="flex-1">
                                        {getFieldDecorator('content')(
                                            <Input.TextArea
                                                onChange={this.onChange}
                                                onPaste={this.onPaste}
                                                className="content-textarea rounded-tl-none border-none transition"
                                                placeholder="Share your contribution"
                                                autoSize={{ minRows: 3 }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item className="mb-0">
                                {getFieldDecorator('images')(
                                    <ConsumerPostImageForm
                                        keys={imageKeys}
                                        fileList={fileList}
                                        onUploadFocus={this.onUploadFocus}
                                        onUploadImage={this.onUploadImage}
                                        onStoreImageKeys={this.onStoreImageKeys}
                                    />
                                )}
                            </Form.Item>
                        </>
                    )}
                    <div className={`CheckInBox ${checkInBoxStyle} flex items-center`}>
                        <div
                            className="rounded-tl rounded-bl text-base text-white"
                            style={{
                                padding: '8px 12px',
                                backgroundColor: PRIMARY,
                            }}
                        >
                            <EnvironmentFilled />
                        </div>
                        <div className="flex-1">
                            {getFieldDecorator('checkIn')(
                                <Select
                                    className="w-full"
                                    size="large"
                                    allowClear
                                    placeholder="Search suburb"
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    notFoundContent={isSearching ? <Spin size="small" /> : null}
                                    onSearch={this.handleSearch}
                                    loading={isSearching}
                                >
                                    {this.renderOptions(dataSource)}
                                </Select>
                            )}
                        </div>
                    </div>
                    <div className={`PostFormBtnWrapper pt-3 text-right ${postButtonDisplay}`}>
                        <Button className="mr-2 text-sm" onClick={this.handleCheckInBox}>
                            Check-In
                        </Button>
                        <Button
                            className="text-sm"
                            type="primary"
                            loading={isLoading}
                            htmlType="submit"
                            disabled={postButtonDisabled}
                        >
                            Post
                        </Button>
                    </div>
                </Form>
            </main>
        );
    }
}

const CREATE_CONSUMER_PAGE_POST = gql`
    mutation createConsumerPagePost($input: CreateConsumerPagePostInput) {
        createConsumerPagePost(input: $input) {
            id
            name
            uuid
            createdAt
            post {
                id
                consumerId
                content
                metaHost
                metaUrl
                metaDescription
                metaImage
                metaTitle
                consumerPageId
                isOwner
                checkIn
                images {
                    id
                    width
                    height
                    url
                }
                isEdited
                contributor {
                    ... on Consumer {
                        id
                        firstName
                        lastName
                        initials
                        profilePicture
                    }
                    ... on ConsumerPage {
                        id
                        name
                        profileImage
                        initials
                    }
                }
            }
        }
    }
`;

export default Form.create()(
    compose(
        withEmitterContext,
        withApollo,
        graphql(CREATE_CONSUMER_PAGE_POST, {
            props: ({ mutate, ownProps }) => ({
                createConsumerPagePost: (input, pageId) =>
                    mutate({
                        variables: { input },
                        update: (store, { data: { createConsumerPagePost } }) => {
                            try {
                                const variables = {
                                    pageId,
                                    filter: { first: 20 },
                                };

                                addRecordToCache(store, ConsumerPageEventsConnection, variables, (data) => ({
                                    ...data,
                                    consumerPage: {
                                        ...data.consumerPage,
                                        profilePostsConnection: {
                                            ...data.consumerPage.profilePostsConnection,
                                            nodes: [
                                                createConsumerPagePost,
                                                ...data.consumerPage.profilePostsConnection.nodes,
                                            ],
                                        },
                                    },
                                }));

                                ownProps.emitter.emit(FeedEventsNames.ON_POST_ADDED);
                            } catch (err) {
                                console.log(err);
                            }
                        },
                    }),
            }),
        })
    )(ConsumerProfilePostForm)
);
