/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

class ConsumerProfileStatistics extends PureComponent {
    static propTypes = { consumerPage: PropTypes.object };

    static defaultProps = { consumerPage: {} };

    render() {
        const { consumerPage } = this.props;
        const { owner } = consumerPage;

        const { statistics } = owner || {};

        const { postCount, followersCount, likesCount, friendsCount } = statistics || {
            postCount: 0,
            followersCount: 0,
            likesCount: 0,
            friendsCount: 0,
        };

        return (
            <Row className="pt-3 pb-1">
                <Col className="cursor-pointer w-16">
                    <h5 className="text-center font-normal text-white">
                        {followersCount.length > 0 ? 'Followers' : 'Follower'}
                    </h5>
                    <h1 className="text-center text-white">{followersCount}</h1>
                </Col>
                <Col className="cursor-pointer w-16">
                    <h5 className="text-center font-normal text-white">{postCount.length > 0 ? 'Posts' : 'Post'}</h5>
                    <h1 className="text-center text-white">{postCount}</h1>
                </Col>
                <Col className="cursor-pointer w-16">
                    <h5 className="text-center font-normal text-white">{likesCount.length > 0 ? 'Likes' : 'Like'}</h5>
                    <h1 className="text-center text-white">{likesCount}</h1>
                </Col>
                <Col className="cursor-pointer w-16">
                    <h5 className="text-center font-normal text-white">
                        {friendsCount.length > 0 ? 'Friends' : 'Friend'}
                    </h5>
                    <h1 className="text-center text-white">{friendsCount}</h1>
                </Col>
            </Row>
        );
    }
}

export default ConsumerProfileStatistics;
