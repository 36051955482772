import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import ConsumerAvatarUpload from '~/components/ConsumerAvatarUpload';

const ConsumerAvatarModal = (props) => {
    const { showAvatarModal, handleAvatarModal, contributorUploadImage, refetch, uploadingImage, consumerPage } = props;
    // console.log('uploadingImage', uploadingImage);

    const { profileImageS3 } = consumerPage || {};
    return (
        <Modal
            wrapClassName="avatar-upload-modal"
            title="Change Profile Picture"
            visible={showAvatarModal}
            onCancel={handleAvatarModal}
            footer={null}
            onOk={handleAvatarModal}
            destroyOnClose
            maskClosable={false}
        >
            <ConsumerAvatarUpload
                profileImage={profileImageS3}
                contributorUploadImage={contributorUploadImage}
                refetch={refetch}
                consumerPage={consumerPage}
                handleAvatarModal={handleAvatarModal}
                uploadingImage={uploadingImage}
            />
        </Modal>
    );
};

ConsumerAvatarModal.propTypes = {
    showAvatarModal: PropTypes.bool,
    uploadingImage: PropTypes.bool,
    consumerPage: PropTypes.object,
    refetch: PropTypes.func,
    handleAvatarModal: PropTypes.func,
    contributorUploadImage: PropTypes.func,
};
ConsumerAvatarModal.defaultProps = {
    showAvatarModal: false,
    uploadingImage: false,
    consumerPage: {},
    refetch: () => {},
    handleAvatarModal: () => {},
    contributorUploadImage: () => {},
};

export default ConsumerAvatarModal;
