/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import { NextSeo } from 'next-seo';

const ConsumerPageSeoHeader = props => {
    const { consumerPage, router } = props;

    if (!consumerPage) {
        return null;
    }

    const { name, profileImage, type, about, pageId } = consumerPage || {};

    const title = `${name} | Realty.com.au`;

    return (
        <NextSeo
            title={title}
            description={about}
            canonical={`https://www.realty.com.au/${pageId}`}
            openGraph={{
                type: type === 'BUSINESS' ? 'website' : 'profile',
                title,
                description: about,
                locale: 'en_US',
                url: `https://www.realty.com.au/${pageId}`,
                images: [
                    {
                        url: profileImage || null,
                        width: 800,
                        height: 600,
                        alt: title,
                        type: 'image/jpeg',
                    },
                ],
            }}
        />
    );
};

ConsumerPageSeoHeader.propTypes = {
    consumerPage: PropTypes.object,
    router: PropTypes.object,
};

ConsumerPageSeoHeader.defaultProps = {
    consumerPage: {},
    router: {},
};

export default withRouter(ConsumerPageSeoHeader);
