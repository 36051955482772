import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import compose from 'lodash.flowright';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import BusinessSendEmailModal from '~/components/BusinessSendEmailModal';
import GuestLoginForm from '~/components/GuestLoginForm';
import ScreenWidthValueProvider from '~/components/ScreenWidthValueProvider';
import { ConsumerFollowPage, ConsumerUnfollowPage } from './Operations.graphql';
import { loginUser } from '~/actions';
import styles from './BusinessSocial.module.scss';

const BusinessSocial = ({ consumerPage, refetch, isAuthenticated, onLoginUser }) => {
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [isShowLoginModal, setIsShowLoginModal] = useState(false);

    const [consumerFollowPage, { loading: following }] = useMutation(ConsumerFollowPage);
    const [consumerUnfollowPage, { loading: unfollowing }] = useMutation(ConsumerUnfollowPage);

    const toggleFollowButton = async (consumerPageId) => {
        const { isFollowed } = consumerPage || {};

        if (isFollowed) {
            await consumerUnfollowPage({ variables: { consumerPageId } }).then((result) => {
                if (result.data.consumerUnfollowPage) {
                    refetch();
                }
            });
        } else {
            await consumerFollowPage({ variables: { consumerPageId } }).then((result) => {
                if (result.data.consumerFollowPage) {
                    refetch();
                }
            });
        }
    };

    const toggleSendEmailModal = () => {
        setShowEmailModal(!showEmailModal);
    };

    const handleLoginModal = (bool) => {
        setIsShowLoginModal(bool);
    };

    const onLoginSuccess = (loginData) => {
        const { accessToken, expiresIn, refreshToken, user, type } = loginData;
        onLoginUser({
            accessToken,
            expiresIn,
            refreshToken,
            user,
            type,
        });
        handleLoginModal(false);
    };

    const checkBeforeFollow = (consumerPageId) => {
        if (isAuthenticated) {
            toggleFollowButton(consumerPageId);
        } else {
            handleLoginModal(true);
        }
    };

    const checkBeforeContact = () => {
        if (isAuthenticated) {
            toggleSendEmailModal();
        } else {
            handleLoginModal(true);
        }
    };

    const { id, contact, website, email, name, isFollowed } = consumerPage || {};
    const followButtonText = isFollowed ? 'Unfollow' : 'Follow';
    const contactDisabled = isEmpty(consumerPage[contact ? contact.toLowerCase() : null]);

    return (
        <div>
            <ScreenWidthValueProvider xs sm md lg>
                {(matches) => (
                    <div className={!matches ? 'mb-3' : ''}>
                        <div className="flex justify-center">
                            <div className="mr-2 flex-1">
                                <Button
                                    loading={following || unfollowing}
                                    type="primary"
                                    style={{ width: '100%', borderRadius: '9999px', fontWeight: '700' }}
                                    // className="w-full rounded-full font-bold"
                                    onClick={() => {
                                        checkBeforeFollow(id);
                                    }}
                                >
                                    {!(following || unfollowing) && followButtonText}
                                </Button>
                            </div>
                            <div className="flex-1">
                                {contact === 'WEBSITE' ? (
                                    <button
                                        disabled={contactDisabled}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={`${styles.contactUsButton} block w-full text-center ${
                                            contactDisabled && `${styles.contactUsButtonDisabled}`
                                        }`}
                                        href={`${website}`}
                                    >
                                        Contact Us
                                    </button>
                                ) : (
                                    <Button
                                        type="primary"
                                        disabled={contactDisabled}
                                        className="w-full rounded-full font-bold"
                                        style={{ width: '100%', borderRadius: '9999px', fontWeight: '700' }}
                                        onClick={checkBeforeContact}
                                    >
                                        Contact Us
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </ScreenWidthValueProvider>
            <BusinessSendEmailModal
                showEmailModal={showEmailModal}
                toggleSendEmailModal={toggleSendEmailModal}
                consumerPageEmail={email}
                consumerPageName={name}
            />
            <Modal
                centered
                visible={isShowLoginModal}
                onOk={() => handleLoginModal(false)}
                onCancel={() => handleLoginModal(false)}
                footer={null}
                zIndex={1060}
            >
                <GuestLoginForm onSuccess={onLoginSuccess} onSuccessAccount={onLoginSuccess} />
            </Modal>
        </div>
    );
};

BusinessSocial.propTypes = {
    consumerPage: PropTypes.shape({
        id: PropTypes.string,
        contact: PropTypes.string,
        website: PropTypes.string,
        email: PropTypes.string,
        name: PropTypes.string,
        isFollowed: PropTypes.bool,
    }),
    refetch: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    onLoginUser: PropTypes.func,
};

BusinessSocial.defaultProps = {
    consumerPage: {},
    refetch: () => {},
    isAuthenticated: false,
    onLoginUser: () => {},
};

export default compose(
    connect(
        (state) => ({
            isAuthenticated: state.auth.isAuthenticated,
            userType: state.auth.type,
        }),
        (dispatch) => ({
            onLoginUser(accessToken) {
                dispatch(loginUser(accessToken));
            },
        })
    )
)(BusinessSocial);
