import { useMutation } from '@apollo/client';
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    FacebookOutlined,
    FolderOutlined,
    GlobalOutlined,
    HomeOutlined,
    InfoCircleOutlined,
    InstagramOutlined,
    MailOutlined,
    PhoneOutlined,
} from '@ant-design/icons';

import { Affix, Button, Col, Input, message, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import BusinessSocial from '../BusinessSocial';
import CategorySelect from '../CategorySelect';
import { UpdateConsumerPageMutation } from './Operations.graphql';

const { TextArea } = Input;

const BusinessPageAbout = ({ isPageOwner, isAuthenticated, consumerPage, refetch }) => {
    const {
        id,
        contributorId,
        pageId,
        name,
        website,
        email,
        phoneNo,
        address,
        instagram,
        facebook,
        about,
        categories,
    } = consumerPage || {};

    const [initialWebsite, setInitialWebsite] = useState(website);
    const [initialEmail, setInitialEmail] = useState(email);
    const [initialPhoneNo, setInitialPhoneNo] = useState(phoneNo);
    const [initialAddress, setInitialAddress] = useState(address);
    const [initialInstagram, setInitialInstagram] = useState(instagram);
    const [initialFacebook, setInitialFacebook] = useState(facebook);
    const [initialAbout, setInitialAbout] = useState(about);
    const [initialCategoryIds, setInitialCategoryIds] = useState(categories);
    const [formErrorCode, setFormErrorCode] = useState(null);

    const [updateConsumerPage, { loading }] = useMutation(UpdateConsumerPageMutation);

    const getErrorMessage = () => {
        let errorMessage = null;

        switch (formErrorCode) {
            case 'SERVER_ERROR':
                errorMessage = 'There was a problem saving user.';
                break;
            default:
                errorMessage = 'Unable to resolve page changes.';
                break;
        }
        return errorMessage;
    };

    const handleSaveChanges = (values) => {
        message.config({ top: 24 });

        const newValue = {
            id,
            contributorId,
            pageId,
            name,
            ...values,
        };

        updateConsumerPage({ variables: { input: newValue } })
            .then(() => {
                refetch();
                setTimeout(() => {
                    message.destroy();
                    message.success('Saved successfully');
                }, 1000);
            })
            .catch((error) => {
                const errors = error.graphQLErrors || [];
                const formErrCode = errors.length > 0 ? errors[0].extensions.code : null;
                setFormErrorCode(formErrCode);
                message.destroy();
                message.error(getErrorMessage());
            });
    };

    const websiteDisplayText = website ? (
        <a className="text-gray-600" href={website}>
            {website}
        </a>
    ) : (
        <span className="text-gray-600">n/a</span>
    );
    const aboutDisplayText = about ? <div className="whitespace-pre-wrap">{about}</div> : <div>n/a</div>;
    const instagramDisplayText = instagram ? <a href={instagram}>{instagram}</a> : <span>n/a</span>;
    const facebookDisplayText = facebook ? <a href={facebook}>{facebook}</a> : <span>n/a</span>;

    return (
        <Row
            justify="center"
            gutter={{
                xs: 0,
                md: 16,
            }}
            className="mb-5"
        >
            <Col xs={24} sm={24} md={24} lg={24} xl={15} xxl={15} className="mb-2 self-start">
                <div className="rounded border border-solid border-gray-300">
                    <div className="mb-5">
                        <div className="px-4 pt-3 pb-1 font-bold text-gray-900">CONTACT INFO</div>
                        <div className="px-4">
                            <div className="break-word mt-3 flex items-center">
                                <GlobalOutlined className="mr-3 text-base" />
                                {isPageOwner ? (
                                    <Input
                                        size="large"
                                        defaultValue={website || undefined}
                                        placeholder="Add your website"
                                        onChange={(event) => {
                                            setInitialWebsite(event.target.value);
                                        }}
                                    />
                                ) : (
                                    websiteDisplayText
                                )}
                            </div>
                            <div className="mt-3 flex items-center">
                                <MailOutlined className="mr-3 text-base" />
                                {isPageOwner ? (
                                    <Input
                                        size="large"
                                        defaultValue={email || undefined}
                                        placeholder="Add your email"
                                        onChange={(event) => {
                                            setInitialEmail(event.target.value);
                                        }}
                                    />
                                ) : (
                                    <span>{email || 'n/a'}</span>
                                )}
                            </div>
                            <div className="mt-3 flex items-center">
                                <PhoneOutlined className="mr-3 text-base" />
                                {isPageOwner ? (
                                    <Input
                                        size="large"
                                        defaultValue={phoneNo || undefined}
                                        placeholder="Add your phone number"
                                        onChange={(event) => {
                                            setInitialPhoneNo(event.target.value);
                                        }}
                                    />
                                ) : (
                                    <span>{phoneNo || 'n/a'}</span>
                                )}
                            </div>
                            <div className="mt-3 flex items-center">
                                <HomeOutlined className="mr-3 text-base" />
                                {isPageOwner ? (
                                    <Input
                                        size="large"
                                        defaultValue={address || undefined}
                                        placeholder="Add your address"
                                        onChange={(event) => {
                                            setInitialAddress(event.target.value);
                                        }}
                                    />
                                ) : (
                                    <span>{address || 'n/a'}</span>
                                )}
                            </div>
                            <div className="mt-3 flex items-center">
                                <InstagramOutlined className="mr-3 text-base" />
                                {isPageOwner ? (
                                    <Input
                                        size="large"
                                        defaultValue={instagram || undefined}
                                        placeholder="Add your instagram"
                                        onChange={(event) => {
                                            setInitialInstagram(event.target.value);
                                        }}
                                    />
                                ) : (
                                    instagramDisplayText
                                )}
                            </div>
                            <div className="mt-3 flex items-center">
                                <FacebookOutlined className="mr-3 text-base" />
                                {isPageOwner ? (
                                    <Input
                                        size="large"
                                        defaultValue={facebook || undefined}
                                        placeholder="Add your facebook"
                                        onChange={(event) => {
                                            setInitialFacebook(event.target.value);
                                        }}
                                    />
                                ) : (
                                    facebookDisplayText
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="mb-5">
                        <div className="px-4 pt-3 font-bold text-gray-900">MORE INFO</div>
                        <div className="mt-4 px-4">
                            <div className="mb-3 flex">
                                <div className="mr-3">
                                    <InfoCircleOutlined />
                                </div>
                                <div className="flex-1">
                                    {isPageOwner ? (
                                        <TextArea
                                            style={{ fontSize: 16 }}
                                            className="mb-2"
                                            placeholder="Add a description"
                                            defaultValue={about || undefined}
                                            autoSize={{ minRows: 4 }}
                                            onChange={(event) => {
                                                setInitialAbout(event.target.value);
                                            }}
                                        />
                                    ) : (
                                        aboutDisplayText
                                    )}
                                </div>
                            </div>
                            <div className="mt-3 flex items-center">
                                <FolderOutlined className="mr-3 text-base" />
                                {isPageOwner ? (
                                    <CategorySelect
                                        value={initialCategoryIds}
                                        onChange={(value) => {
                                            setInitialCategoryIds(value);
                                        }}
                                    />
                                ) : (
                                    <span>{!isEmpty(categories) ? categories[0].name : 'Uncategorized'}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    {isPageOwner && (
                        <div className="mb-5 flex">
                            <div className="flex-1 px-4 text-right">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        handleSaveChanges({
                                            website: initialWebsite,
                                            email: initialEmail,
                                            phoneNo: initialPhoneNo,
                                            address: initialAddress,
                                            instagram: initialInstagram,
                                            facebook: initialFacebook,
                                            about: initialAbout,
                                            categoryId: initialCategoryIds
                                                ? initialCategoryIds.map(({ id: categoryId }) => categoryId)
                                                : [],
                                        });
                                    }}
                                    disabled={loading}
                                    loading={loading}
                                    size="large"
                                >
                                    {loading ? 'Saving' : 'Save'}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={0} xl={9} xxl={9} className="justify-between">
                <Affix offsetTop={30}>
                    <div>
                        <BusinessSocial
                            consumerPage={consumerPage}
                            refetch={refetch}
                            isAuthenticated={isAuthenticated}
                        />
                    </div>
                </Affix>
            </Col>
        </Row>
    );
};

BusinessPageAbout.propTypes = {
    isPageOwner: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    consumerPage: PropTypes.object,
    refetch: PropTypes.func,
};

BusinessPageAbout.defaultProps = {
    isPageOwner: false,
    isAuthenticated: false,
    consumerPage: {},
    refetch: () => {},
};

export default BusinessPageAbout;
