import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { graphql } from '@apollo/client/react/hoc';
import { Avatar, Card } from 'antd';
import compose from 'lodash.flowright';
import Error from '../Error';
import FollowCardTitleCount from '../FollowCardTitleCount';
import { FollowedPageQuery } from './Operations.graphql';
import PRIMARY from '~/constants/colors';

class ConsumerProfileFollowingPageList extends PureComponent {
    static propTypes = { data: PropTypes.object };

    static defaultProps = { data: {} };

    render() {
        const {
            data: { loading, error, consumerPage },
        } = this.props;

        if (error) return <Error error={error} />;

        const { owner } = consumerPage || {};
        const { followedPagesConnection } = owner || {};
        const followedPages = followedPagesConnection ? followedPagesConnection.nodes : [];

        return (
            <Card
                loading={loading}
                bodyStyle={{ padding: 0 }}
                bordered={false}
                className="rounded shadow"
                title={<FollowCardTitleCount title="Pages" count={followedPages.length} />}
            >
                <main className="flex flex-wrap px-6 py-3">
                    {followedPages.length > 0 ? (
                        <>
                            {followedPages.map(({ id, initials, name, profileImage, pageId }) => (
                                <Link key={pageId} href={`/${pageId}`}>
                                    <Avatar
                                        className={`contributor-avatar mb-2 mr-2 cursor-pointer bg-blue-500 bg-white font-bold avatar-${initials[0].toLowerCase()}`}
                                        src={profileImage}
                                        key={id}
                                        size={50}
                                        title={name}
                                        style={{ border: `2px solid ${PRIMARY}`, color: PRIMARY }}
                                    >
                                        {initials}
                                    </Avatar>
                                </Link>
                            ))}
                        </>
                    ) : (
                        <span>No pages yet</span>
                    )}
                </main>
            </Card>
        );
    }
}

export default compose(
    graphql(FollowedPageQuery, {
        options: ({ pageId }) => ({
            variables: {
                pageId,
                filter: { first: 7 },
            },
        }),
    })
)(ConsumerProfileFollowingPageList);
