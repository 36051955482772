import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { graphql } from '@apollo/client/react/hoc';
import { HomeOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Card } from 'antd';
import { equalsIgnoreCase } from '~/utils/string';
import Error from '../Error';
import FollowCardTitleCount from '../FollowCardTitleCount';
import { FollowedPropertyQuery } from './Operations.graphql';
import PRIMARY from '~/constants/colors';

class ConsumerProfileFollowingPropertyList extends PureComponent {
    static propTypes = {
        data: PropTypes.object,
        editable: PropTypes.bool,
    };

    static defaultProps = {
        data: {},
        editable: false,
    };

    state = { loadingMore: false };

    handleLoadMore = async () => {
        const {
            data: { fetchMore, consumerPage },
        } = this.props;

        this.setState({ loadingMore: true });

        await fetchMore({
            variables: {
                filter: {
                    after: consumerPage.owner.followedPropertiesConnection.pageInfo.endCursor,
                    first: 9,
                },
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const { nodes } = fetchMoreResult.consumerPage.owner.followedPropertiesConnection;

                return nodes.length
                    ? {
                          consumerPage: {
                              ...previousResult.consumerPage,
                              owner: {
                                  ...previousResult.consumerPage.owner,
                                  followedPropertiesConnection: {
                                      ...fetchMoreResult.consumerPage.owner.followedPropertiesConnection,
                                      nodes: [
                                          ...previousResult.consumerPage.owner.followedPropertiesConnection.nodes,
                                          ...nodes,
                                      ],
                                  },
                              },
                          },
                      }
                    : previousResult;
            },
        });
    };

    render() {
        const {
            data: { loading, error, consumerPage },
            editable,
        } = this.props;
        const { loadingMore } = this.state;

        if (error) return <Error error={error} />;

        const { owner } = consumerPage || {};
        const { followedPropertiesConnection } = owner || {};

        const followedProperties = followedPropertiesConnection ? followedPropertiesConnection.nodes : [];

        const showLoadingMore = followedPropertiesConnection
            ? followedPropertiesConnection.pageInfo.hasNextPage
            : false;
        const totalProperties = followedPropertiesConnection ? followedPropertiesConnection.totalCount : 0;
        const remainingProperties = showLoadingMore ? totalProperties - followedProperties.length : 0;
        const loadMore = showLoadingMore && !loading && (
            <div
                style={{
                    width: 50,
                    height: 50,
                    backgroundColor: PRIMARY,
                }}
                className="flex cursor-pointer items-center justify-center rounded-full text-base text-white"
                onClick={this.handleLoadMore}
                aria-hidden
            >
                {loadingMore && <LoadingOutlined />}
                {!loadingMore && <div>{remainingProperties}+</div>}
            </div>
        );

        return (
            <Card
                loading={loading}
                bordered={false}
                bodyStyle={{ padding: 0 }}
                className="rounded shadow"
                title={<FollowCardTitleCount title="Properties" count={followedProperties.length} />}
                extra={
                    editable && (
                        <Link href="/buy/search" className="rounded-full p-2">
                            <PlusOutlined />
                        </Link>
                    )
                }
            >
                <main className="flex flex-wrap px-6 py-3">
                    {followedProperties.length > 0 ? (
                        <>
                            {followedProperties.map(({ slugUrl, slugUrlPrefix, address, objects }) => {
                                const filteredObjects = objects.filter(({ isMain, type }) => isMain && type === 'IMG');
                                const img = filteredObjects && `${filteredObjects[0].url}?w=100&h=100&fit=crop`;
                                return (
                                    <Link
                                        href={`/${slugUrlPrefix}/${slugUrl}`}
                                        key={slugUrl}
                                        className="mb-2 mr-2 inline-block"
                                    >
                                        <Avatar
                                            className="rounded-full bg-white"
                                            size={50}
                                            src={img}
                                            icon={<HomeOutlined />}
                                            shape="square"
                                            alt={address}
                                            title={address}
                                            style={{ border: `2px solid ${PRIMARY}`, color: PRIMARY }}
                                        />
                                    </Link>
                                );
                            })}
                            {loadMore}
                        </>
                    ) : (
                        <span>No properties yet</span>
                    )}
                </main>
            </Card>
        );
    }
}

export default graphql(FollowedPropertyQuery, {
    options: ({ pageId }) => ({
        variables: {
            pageId,
            filter: { first: 9 },
        },
    }),
})(ConsumerProfileFollowingPropertyList);
