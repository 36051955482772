import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { FolderOutlined, GlobalOutlined, HeartOutlined, MailOutlined } from '@ant-design/icons';
import { Affix, Col, Row } from 'antd';
import replace from 'lodash/replace';
import trimEnd from 'lodash/trimEnd';
import truncate from 'lodash/truncate';
import clsx from 'clsx';
import BusinessPostForm from '~/components/BusinessPostForm';
import BusinessPosts from '~/components/BusinessPosts';
import BusinessSocial from '~/components/BusinessSocial';
import ImagesSliderModal from '~/components/ImagesSliderModal';
import ScreenWidthValueProvider from '~/components/ScreenWidthValueProvider';
import styles from './BusinessPageHome.module.scss';

const BusinessPageHome = ({ isPageOwner, isAuthenticated, consumerPage, refetch }) => {
    const [carouselVisible, setCarouselVisible] = useState(false);
    const [carouselImages, setCarouselImages] = useState(null);
    const [initialSlide, setInitialSlide] = useState(null);

    const handleImageCarousel = (images, index) => {
        setCarouselVisible(true);
        setCarouselImages(images);
        setInitialSlide(index);
    };

    const onImagesModalClose = () => {
        setCarouselVisible(false);
    };

    const { id, pageId, contributorId, website, email, followCount, categories, images } = consumerPage || {};

    return (
        <>
            <Row
                justify="center"
                gutter={{
                    xs: 0,
                    xl: 16,
                }}
            >
                <Col xs={24} xl={15} className="mb-2 self-start">
                    <ScreenWidthValueProvider xs sm md lg>
                        {(matches) =>
                            matches ? (
                                <>
                                    <div className="mb-5 rounded border border-solid border-gray-300 p-3">
                                        <div className="mb-3 flex justify-between">
                                            <div className="text-base font-bold text-gray-900">About</div>
                                            <div className="text-sm">
                                                <Link
                                                    href={`/${pageId}/about`}
                                                    className="text-[#495264] transition delay-75 ease-in-out hover:text-[#3b82f6]"
                                                >
                                                    {isPageOwner ? 'Edit' : 'See All'}
                                                </Link>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="break-word mb-1 flex items-center">
                                                <GlobalOutlined className="mr-2 text-base" />
                                                {website ? (
                                                    <a
                                                        href={website}
                                                        className="text-[#495264]"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {trimEnd(replace(website, /^(https?):\/\//, ''), '/')}
                                                    </a>
                                                ) : (
                                                    <span>n/a</span>
                                                )}
                                            </div>
                                            <div className="break-word mb-1">
                                                <MailOutlined className="mr-3" />
                                                <span>{email || 'n/a'}</span>
                                            </div>
                                            <div className="mb-2">
                                                <FolderOutlined className="mr-3" />
                                                <span>
                                                    {categories.length > 0 ? categories[0].name : 'Uncategorized'}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-5 rounded border border-solid border-gray-300 p-3">
                                        <div className="mb-3 text-base font-bold text-gray-900">Community</div>
                                        <div>
                                            <div className="mb-1">
                                                <HeartOutlined className="mr-3" />
                                                <span>{followCount?.toLocaleString() ?? 0} people follow this</span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ''
                            )
                        }
                    </ScreenWidthValueProvider>
                    {images && images.length > 0 && (
                        <div
                            className={`${styles.photosDiv} mb-5 overflow-hidden rounded border border-solid border-slate-300`}
                        >
                            <div className="flex items-center justify-between px-4 py-3">
                                <div className="text-base font-bold text-gray-900">Photos</div>
                                <div className="text-sm">
                                    <Link
                                        href={`/${pageId}/photos`}
                                        className="text-[#465964] transition delay-75 ease-in-out hover:text-[#3b82f6]"
                                    >
                                        See All
                                    </Link>
                                </div>
                            </div>
                            <div className={`${styles.photosContainer} flex flex-wrap`}>
                                {images.slice(0, 3).map((image, index) => {
                                    const imageBoxWidth =
                                        index === 0 ? `${styles.photosFullImageBox} mb-1` : styles.photosHalfImageBox;

                                    return (
                                        <div
                                            className={clsx(
                                                `${imageBoxWidth} ${index === 1 ? 'mr-1' : ''}, ${
                                                    styles.photosBox
                                                } shrink-0 grow cursor-pointer`
                                            )}
                                            style={{ backgroundImage: `url(${image.url})` }}
                                            key={image.url}
                                            onClick={() => handleImageCarousel(images, index)}
                                            aria-hidden
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    {isPageOwner && (
                        <BusinessPostForm
                            consumerPageId={id}
                            pageId={pageId}
                            isAuthenticated={isAuthenticated}
                            refetch={refetch}
                        />
                    )}
                    <BusinessPosts
                        contributorId={contributorId}
                        consumerPageId={id}
                        consumerPage={consumerPage}
                        pageId={pageId}
                        isAuthenticated={isAuthenticated}
                        refetch={refetch}
                    />
                </Col>
                <Col xs={0} xl={9} className="justify-between">
                    <Affix offsetTop={30}>
                        <div>
                            <div>
                                <BusinessSocial
                                    consumerPage={consumerPage}
                                    refetch={refetch}
                                    isAuthenticated={isAuthenticated}
                                />
                            </div>
                            <div className="mb-3 rounded border border-solid border-slate-300 p-3">
                                <div className="mb-3 flex justify-between">
                                    <div className="text-base font-bold text-gray-900">About</div>
                                    <div className="text-sm">
                                        <Link
                                            href={`/${pageId}/about`}
                                            className="transition delay-75 ease-in-out hover:text-[#3b82f6]"
                                        >
                                            {isPageOwner ? 'Edit' : 'See All'}
                                        </Link>
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-1 break-all">
                                        <GlobalOutlined className="mr-2.5 text-base" />
                                        {website ? (
                                            <a
                                                className={styles.aboutWebLink}
                                                href={website}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {truncate(trimEnd(replace(website, /^(https?):\/\//, ''), '/'), {
                                                    length: 32,
                                                })}
                                            </a>
                                        ) : (
                                            <span>n/a</span>
                                        )}
                                    </div>
                                    <div className="mb-1 break-all">
                                        <MailOutlined className="mr-3 text-base" />
                                        <span>{email || 'n/a'}</span>
                                    </div>
                                    <div className="mb-2">
                                        <FolderOutlined className="mr-3 text-base" />
                                        <span>{categories.length > 0 ? categories[0].name : 'Uncategorized'}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="rounded border border-solid border-slate-300 p-3">
                                <div className="mb-3 text-base font-bold text-gray-900">Community</div>
                                <div>
                                    <div className="mb-1">
                                        <HeartOutlined className="mr-3 text-base" />
                                        <span>{followCount?.toLocaleString() ?? 0} people follow this</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Affix>
                </Col>
            </Row>
            <ImagesSliderModal
                visible={carouselVisible}
                images={carouselImages}
                initialSlide={initialSlide}
                onClose={onImagesModalClose}
            />
        </>
    );
};

BusinessPageHome.propTypes = {
    isPageOwner: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    consumerPage: PropTypes.shape({
        id: PropTypes.string,
        pageId: PropTypes.string,
        contributorId: PropTypes.number,
        website: PropTypes.string,
        email: PropTypes.string,
        followCount: PropTypes.string,
        categories: PropTypes.array,
        images: PropTypes.array,
    }),
    refetch: PropTypes.func,
};

BusinessPageHome.defaultProps = {
    isPageOwner: false,
    isAuthenticated: false,
    consumerPage: {},
    refetch: () => {},
};

export default BusinessPageHome;
