import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CameraOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PRIMARY from '~/constants/colors';
import BusinessAvatarModal from '~/components/BusinessAvatarModal';
import ImagesSliderModal from '~/components/ImagesSliderModal';
import ScreenWidthValueProvider from '~/components/ScreenWidthValueProvider';
import { ContributorUploadImageMutation } from './Operations.graphql';
import styles from './BusinessUploadProfilePhoto.module.scss';

const BusinessUploadProfilePhoto = ({ consumerPageId, profileImageS3, profileImage, isPageOwner, refetch }) => {
    const [showAvatarModal, setShowAvatarModal] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);

    const [contributorUploadImage, { loading: uploadingImage }] = useMutation(ContributorUploadImageMutation);

    const handleImagePreview = (url) => {
        if (url) {
            setPreviewVisible(true);
        }
    };

    const handleCloseImagePreview = () => {
        setPreviewVisible(false);
    };

    const handleAvatarModal = () => {
        setShowAvatarModal(!showAvatarModal);
    };

    const profileImageClass = profileImage
        ? `${styles.businessUploadProfileButtonWithImage}`
        : `${styles.businessUploadProfileButton}`;
    const isPageOwnerClass = isPageOwner ? 'block' : 'hidden';
    const profileImageBackground = profileImage ? 'none' : PRIMARY;

    return (
        <div>
            <div className={`${styles.BusinessUploadProfilePhoto} relative z-10`}>
                <div
                    className={`${styles.businessProfileBox} ${!profileImage ? `${styles.noImage}` : 'cursor-pointer'}`}
                    style={{ background: profileImageBackground }}
                    onClick={() => handleImagePreview(profileImage)}
                    aria-hidden
                >
                    {profileImage && <img src={profileImage} className="w-full" alt={profileImage} />}
                </div>
                <div className={`${profileImageClass} ${isPageOwnerClass} absolute z-20`}>
                    <ScreenWidthValueProvider xs sm md lg>
                        {(matches) =>
                            matches ? (
                                <Button
                                    className="text-base"
                                    type="primary"
                                    icon={profileImage ? <EditOutlined /> : <CameraOutlined />}
                                    onClick={handleAvatarModal}
                                />
                            ) : (
                                <Button
                                    type="primary"
                                    className="text-base"
                                    icon={profileImage ? <EditOutlined /> : <CameraOutlined />}
                                    onClick={handleAvatarModal}
                                >
                                    {!profileImage && 'Upload Photo'}
                                </Button>
                            )
                        }
                    </ScreenWidthValueProvider>
                </div>
            </div>
            <BusinessAvatarModal
                profileImage={profileImageS3}
                handleAvatarModal={handleAvatarModal}
                showAvatarModal={showAvatarModal}
                contributorUploadImage={contributorUploadImage}
                uploadingImage={uploadingImage}
                refetch={refetch}
                consumerPageId={consumerPageId}
            />

            <ImagesSliderModal
                visible={previewVisible}
                images={profileImage ? [{ url: profileImage }] : []}
                onClose={handleCloseImagePreview}
            />
        </div>
    );
};

BusinessUploadProfilePhoto.propTypes = {
    consumerPageId: PropTypes.string,
    profileImage: PropTypes.string,
    profileImageS3: PropTypes.string,
    isPageOwner: PropTypes.bool,
    refetch: PropTypes.func,
};

BusinessUploadProfilePhoto.defaultProps = {
    consumerPageId: null,
    profileImageS3: null,
    profileImage: null,
    isPageOwner: false,
    refetch: () => {},
};

export default BusinessUploadProfilePhoto;
