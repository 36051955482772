import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { graphql } from '@apollo/client/react/hoc';
import { PlusOutlined } from '@ant-design/icons';
import { Avatar, Card } from 'antd';
import Error from '../Error';
import FollowCardTitleCount from '../FollowCardTitleCount';
import { ConsumerFriendsQuery } from './Operations.graphql';
import PRIMARY from '~/constants/colors';

class ConsumerProfileFriendList extends PureComponent {
    static propTypes = {
        data: PropTypes.object,
        editable: PropTypes.bool,
    };

    static defaultProps = {
        data: {},
        editable: null,
    };

    render() {
        const {
            data: { loading, error, consumerPage },
            editable,
        } = this.props;
        if (error) return <Error error={error} />;

        const { owner } = consumerPage || {};
        const { consumerFriendsConnection } = owner || {};
        const friends = consumerFriendsConnection ? consumerFriendsConnection.nodes : [];

        return (
            <Card
                loading={loading}
                bodyStyle={{ padding: 0 }}
                bordered={false}
                className="mt-4 rounded shadow"
                title={<FollowCardTitleCount title="Friends" count={friends.length} />}
                extra={
                    editable && (
                        <Link href="/lifestyle" as="/lifestyle" className="rounded-full p-2">
                            <PlusOutlined />
                        </Link>
                    )
                }
            >
                <main className="flex flex-wrap px-6 py-3">
                    {friends.length > 0 ? (
                        <>
                            {friends.map(({ id, initials, firstName, lastName, profilePageId, profilePicture }) => {
                                const firstLetter = initials.charAt(0).toLowerCase();
                                return (
                                    <Link key={profilePageId} href={`/${profilePageId || ''}`}>
                                        <div className="flex cursor-pointer align-middle">
                                            <Avatar
                                                className={`contributor-avatar mb-1 mr-1 mt-1 bg-white font-bold avatar-${firstLetter}`}
                                                key={id}
                                                size={50}
                                                src={profilePicture}
                                                title={`${firstName || ''} ${lastName || ''}`}
                                                style={{
                                                    border: `2px solid ${PRIMARY}`,
                                                    color: PRIMARY,
                                                }}
                                            >
                                                {initials}
                                            </Avatar>
                                            <div className="ml-2 flex items-center text-gray-900">
                                                <div>
                                                    <div className="font-semibold">
                                                        {firstName} {lastName}{' '}
                                                    </div>

                                                    <span className="inline-block text-xs font-normal">
                                                        @{profilePageId}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                );
                            })}
                        </>
                    ) : (
                        <span>No friends yet</span>
                    )}
                </main>
            </Card>
        );
    }
}

export default graphql(ConsumerFriendsQuery, {
    options: ({ pageId }) => ({
        variables: {
            pageId,
            filter: {
                first: 7,
                status: 'ACCEPTED',
            },
        },
    }),
})(ConsumerProfileFriendList);
