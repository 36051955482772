import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider } from 'antd';
import Error from '~/components/Error';
import PropertyCard from '~/components/PropertyCard';
import AgentPropertiesSectionSkeleton from './AgentPropertiesSectionSkeleton';
import { AgentQuery } from './Operations.graphql';

const AgentPropertiesSection = ({ agentId, group }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { loading, data, error, fetchMore } = useQuery(AgentQuery, {
        variables: {
            id: agentId,
            filter: {
                first: 4,
                group,
            },
        },
    });

    const handleLoadMore = async () => {
        const { agent } = data;
        setIsLoading(true);

        await fetchMore({
            variables: {
                id: agentId,
                filter: {
                    first: 4,
                    after: agent.propertiesConnection.pageInfo.endCursor,
                    group,
                },
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const { nodes } = fetchMoreResult.agent.propertiesConnection;

                return nodes.length
                    ? {
                          agent: {
                              ...fetchMoreResult.agent,
                              propertiesConnection: {
                                  ...fetchMoreResult.agent.propertiesConnection,
                                  nodes: [...previousResult.agent.propertiesConnection.nodes, ...nodes],
                              },
                          },
                      }
                    : previousResult;
            },
        });
        setIsLoading(false);
    };

    const title = (
        <h3 className="border-l-4 border-primary-500 font-semibold capitalize pl-2 my-2">
            {group !== 'SOLD' && 'For'} {(group === 'BUY' && 'sale') || group.toLowerCase()}
        </h3>
    );

    if (error) return <Error error={error} />;
    if (loading)
        return (
            <main className="AgentPropertiesSection">
                {title}
                <AgentPropertiesSectionSkeleton />
            </main>
        );

    const {
        agent: {
            propertiesConnection: {
                nodes,
                totalCount,
                pageInfo: { hasNextPage },
            },
        },
    } = data;

    if (!nodes.length) return <div />;

    return (
        <>
            <main className="AgentPropertiesSection">
                <section className="flex flex-wrap items-center">
                    {title} <span className="pl-2 text-gray-600 text-sm">({totalCount} Properties)</span>
                </section>
                <section className="flex flex-wrap justify-center">
                    {nodes.map(property => (
                        <div className="md:w-1/2 sm:w-5/6 w-full p-2" key={property.id}>
                            <PropertyCard
                                carouselImageHeight="100%"
                                property={property}
                                isHomePage
                                showCardActions={false}
                            />
                        </div>
                    ))}
                </section>
            </main>
            <div className="text-center py-2">
                {hasNextPage && (
                    <Button ghost type="primary" onClick={handleLoadMore} loading={isLoading}>
                        Load More
                    </Button>
                )}
            </div>
            {group !== 'SOLD' && <Divider />}
        </>
    );
};

AgentPropertiesSection.propTypes = {
    agentId: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired,
};

export default AgentPropertiesSection;
