import { useQuery } from '@apollo/client';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import PropertiesLocationQuery from '../../queries/AgentPropertiesLocationQuery.graphql';
import Error from '../Error';
import LoadingDots from '../LoadingDots';

const PropertiesLocation = memo(props => {
    const { id, group, handleChange } = props;

    const { data, loading, error } = useQuery(PropertiesLocationQuery, {
        ssr: false,
        variables: {
            id,
            group,
        },
    });

    if (loading) return <LoadingDots />;
    if (error) return <Error error={error} />;

    const { agent } = data || {};
    const { propertiesLocation = [] } = agent || {};

    return (
        propertiesLocation.length > 0 && (
            <Select
                defaultValue="all"
                onChange={(value, option) => handleChange(value, option)}
                className="lg:w-1/31 md:w-1/21 w-5/61 capitalize max-w-xs w-full"
            >
                <Select.Option value="all" key="all">
                    All suburbs
                </Select.Option>
                {propertiesLocation.map(
                    ({ id, suburb, count }) =>
                        !!suburb && (
                            <Select.Option value={suburb} key={id} className="capitalize">
                                {suburb.toLowerCase()} <span className="text-sm text-gray-500">({count})</span>
                            </Select.Option>
                        )
                )}
            </Select>
        )
    );
});

PropertiesLocation.propTypes = {
    group: PropTypes.string,
    id: PropTypes.string,
    handleChange: PropTypes.func,
};

PropertiesLocation.defaultProps = {
    group: '',
    id: '',
    handleChange: () => {},
};

export default PropertiesLocation;
