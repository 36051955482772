/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { EditOutlined } from '@ant-design/icons';
import { Card } from 'antd';

class ConsumerProfileAbout extends PureComponent {
    static propTypes = { consumerPage: PropTypes.object };

    static defaultProps = { consumerPage: {} };

    render() {
        const { consumerPage } = this.props;

        const { about, isPageOwner } = consumerPage || {};

        if (!about) return null;

        return (
            <div className="pt-3">
                <Card
                    bodyStyle={{ padding: 0 }}
                    bordered={false}
                    className="rounded shadow"
                    title={<span className="font-normal">About Me</span>}
                    extra={
                        isPageOwner && (
                            <Link href="/settings" className="rounded-full p-2">
                                <EditOutlined />
                            </Link>
                        )
                    }
                >
                    <main className="flex flex-wrap px-6 py-3">{about}</main>
                </Card>
            </div>
        );
    }
}

export default ConsumerProfileAbout;
