/* eslint-disable indent */
/* eslint-disable react/forbid-prop-types */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { NextSeo } from 'next-seo';
// import { compose } from 'redux';
import withApolloProvider from '~/lib/apollo/with-apollo-provider';
// import withReduxProvider from '~/lib/redux/with-redux-store';
import PublicLayout from '~/layouts/PublicLayout';
import AgentProfilePage from '~/pages/AgentProfilePage';
import BusinessPage from '~/pages/BusinessPage';
import ConsumerProfilePage from '~/pages/ConsumerProfilePage';
import Error from '~/components/Error';
import LoadingDots from '~/components/LoadingDots';
import NotFound from '~/components/NotFound';
import ConsumerPageQuery from '~/queries/ConsumerPageQuery.graphql';
import ConsumerPageInfoQuery from '~/queries/ConsumerPageInfoQuery.graphql';

const NotFoundContent = memo(() => (
    <PublicLayout>
        <NextSeo
            noindex
            title="Not Found | Realty.com.au"
            description="We could not find what you were looking for on Realty."
            openGraph={{
                title: 'Not Found | Realty.com.au',
                images: [],
            }}
        />
        <NotFound />
    </PublicLayout>
));

const SocialPage = ({ isAuthenticated, pageId }) => {
    const { data, loading, error, refetch } = useQuery(ConsumerPageQuery, {
        skip: !pageId,
        variables: { pageId },
    });

    if (loading) {
        return (
            <div className="flex h-screen items-center">
                <LoadingDots />
            </div>
        );
    }
    if (error)
        return (
            <PublicLayout>
                <Error error={error} />{' '}
            </PublicLayout>
        );
    const { consumerPage } = data;

    if (!consumerPage) return <NotFoundContent />;

    let page = '';

    switch (consumerPage.type) {
        case 'BUSINESS':
            page = <BusinessPage consumerPage={consumerPage} isAuthenticated={isAuthenticated} refetch={refetch} />;
            break;
        case 'PROFILE':
            page = (
                <ConsumerProfilePage consumerPage={consumerPage} isAuthenticated={isAuthenticated} refetch={refetch} />
            );
            break;
        case 'AGENT':
            page = <AgentProfilePage consumerPage={consumerPage} isAuthenticated={isAuthenticated} refetch={refetch} />;
            break;
        default:
            page = <NotFoundContent />;
            break;
    }
    return page;
};

SocialPage.getInitialProps = async ({ query, apolloClient, res }) => {
    const { pageId } = query;

    if (!pageId && res) {
        res.statusCode = 404;
        return {
            pageId: null,
            errorCode: 404,
        };
    }

    const { data } = await apolloClient.query({
        query: ConsumerPageInfoQuery,
        variables: { pageId: pageId || null },
    });

    const { consumerPage } = data || {};

    if (!consumerPage && res) {
        res.statusCode = 404;
    }

    const errorCode = res && res.statusCode > 200 ? res.statusCode : false;

    return {
        pageId,
        errorCode,
    };
};

SocialPage.propTypes = {
    isAuthenticated: PropTypes.bool,
    data: PropTypes.object,
};

SocialPage.defaultProps = {
    isAuthenticated: false,
    data: {},
};

export default withApolloProvider(
    // withReduxProvider
    connect((state) => ({ isAuthenticated: state.auth.isAuthenticated && state.auth.type === 'CONSUMER' }))(SocialPage)
);
