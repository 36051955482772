/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-promise-executor-return */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { CameraOutlined, EditOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import ConsumerAvatarModal from '~/components/ConsumerAvatarModal';
import ConsumerProfileFollowing from '~/components/ConsumerProfileStatistics';
import FollowConsumerButton from '~/components/FollowConsumerButton';
import FriendRequestButton from '~/components/FriendRequestButton';
import PRIMARY from '~/constants/colors';
import { ContributorUploadImageMutation } from './Operations.graphql';
import styles from './ConsumerProfileBanner.module.scss';

const ConsumerProfileBanner = memo((props) => {
    const { consumerPage, refetch, isPageOwner, coverImage, profileImage } = props;
    const [uploading, setUploading] = useState(false);
    const [showAvatarModal, setShowAvatarModal] = useState(false);
    const [contributorUploadImage, { loading: uploadingImage }] = useMutation(ContributorUploadImageMutation);

    const customRequest = (key, { file, onSuccess, onError }) =>
        new Promise((resolve) => {
            message.config({ top: 24 });

            const imageFormats = ['image/jpeg', 'image/png'];
            const exceededFileSize = key === 'profileImage' ? file.size > 2000000 : file.size > 3000000;

            if (!imageFormats.includes(file?.type)) {
                return false;
            }
            if (exceededFileSize) {
                return false;
            }

            contributorUploadImage({
                variables: {
                    id: consumerPage?.id,
                    file,
                    type: 'coverImage',
                },
            })
                .then(({ data: { contributorUploadImage } }) => {
                    onSuccess(contributorUploadImage, file);
                    setUploading(true);
                    refetch();
                    resolve(true);
                    setUploading(false);
                })
                .catch(() => {
                    onError();
                    setUploading(false);
                    message.destroy();
                    message.error('Upload failed');
                });
        });

    const handleUpload = (key, info) => {
        message.config({ top: 24 });
        const { fileList } = info;

        const imageFormats = ['image/jpeg', 'image/png'];
        const newFileList = fileList.filter((item) => imageFormats.includes(item.type));
        const validFileSize =
            key === 'profile'
                ? fileList.filter((item) => item.size <= 2000000)
                : fileList.filter((item) => item.size <= 3000000);

        if (newFileList.length < 1) {
            message.destroy();
            message.error('You can only upload JPG or PNG file!');
            return false;
        }

        if (validFileSize.length < 1) {
            message.destroy();
            message.error(`Image must be smaller than ${key === 'profile' ? '(2MB)' : '(3MB)'}.`);
            return false;
        }
    };

    const handleAvatarModal = () => {
        setShowAvatarModal(!showAvatarModal);
    };

    const { owner, pageId, isFollowed } = consumerPage || {};
    const { firstName, lastName, initials, id, friendInfo } = owner || {};

    return (
        <main
            className={`${styles.ConsumerProfileBanner} relative z-0 p-4 pt-6 transition`}
            style={{ background: coverImage ? `url(${coverImage}) center top / cover no-repeat` : '#24519c' }}
        >
            {!coverImage && isPageOwner && (
                <div className="pin absolute z-20 pt-3 pl-3 sm:inset-auto sm:right-0 sm:pt-0 sm:pl-0 sm:pr-4 lg:inset-auto lg:w-full lg:pt-8 lg:pr-0 lg:text-center">
                    <Upload
                        accept=".jpeg, .JPEG, .png, .PNG, .jpg, .JPG"
                        showUploadList={false}
                        customRequest={(info) => {
                            customRequest('coverImage', info);
                        }}
                        onChange={(info) => {
                            handleUpload('cover', info);
                        }}
                    >
                        <Button
                            className="font-bold"
                            type="primary"
                            loading={uploading === 'coverImage'}
                            icon={uploadingImage ? <LoadingOutlined /> : <UploadOutlined />}
                        >
                            {uploading === 'coverImage' ? 'Uploading' : 'Upload Cover Photo'}
                        </Button>
                    </Upload>
                </div>
            )}

            {coverImage && isPageOwner && (
                <div className="pin absolute z-20 pt-3 pl-3 sm:inset-auto sm:right-0 sm:pt-0 sm:pl-0 sm:pr-4">
                    <Upload
                        accept=".jpeg, .JPEG, .png, .PNG, .jpg, .JPG"
                        showUploadList={false}
                        customRequest={(info) => {
                            customRequest('coverImage', info);
                        }}
                        onChange={(info) => {
                            handleUpload('cover', info);
                        }}
                    >
                        <Button
                            className="font-bold"
                            type="primary"
                            loading={uploadingImage === 'coverImage'}
                            icon={uploadingImage ? <LoadingOutlined /> : <EditOutlined />}
                        >
                            {uploadingImage === 'coverImage' ? 'Uploading' : 'Edit Cover Photo'}
                        </Button>
                    </Upload>
                </div>
            )}

            <section className="absolute bottom-0 left-0 flex w-full flex-col items-center justify-center sm:flex-row">
                {!profileImage && (
                    <div className={`${styles.noAvatar}`}>
                        <div className={`${styles.noAvatarButtonWrapper}`}>
                            {isPageOwner ? (
                                <Button
                                    className="font-bold"
                                    size="small"
                                    type="primary"
                                    icon={<CameraOutlined />}
                                    onClick={handleAvatarModal}
                                >
                                    Upload Picture
                                </Button>
                            ) : (
                                <span style={{ color: PRIMARY }} className="text-3xl md:text-5xl">
                                    {initials}
                                </span>
                            )}
                        </div>
                    </div>
                )}
                {profileImage && (
                    <div
                        className={` ${styles.hasAvatar} relative`}
                        style={{ background: `#fff url(${profileImage}) center / cover no-repeat` }}
                    >
                        {isPageOwner && (
                            <div className={`${styles.editProfileImageButton} absolute z-10`}>
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<EditOutlined />}
                                    onClick={handleAvatarModal}
                                />
                            </div>
                        )}
                    </div>
                )}
                <ConsumerAvatarModal
                    handleAvatarModal={handleAvatarModal}
                    showAvatarModal={showAvatarModal}
                    contributorUploadImage={contributorUploadImage}
                    refetch={refetch}
                    consumerPage={consumerPage}
                    uploadingImage={uploadingImage}
                />
                <div className="w-full grow p-2 text-center sm:text-left md:w-auto md:pt-12">
                    <h2 className="text-overflow text-white">
                        {firstName} {lastName}
                    </h2>
                    <h4 className="text-overflow py-1 font-medium text-gray-100">@{pageId}</h4>
                    <div className="flex flex-wrap items-center justify-center sm:justify-between">
                        <ConsumerProfileFollowing consumerPage={consumerPage} />
                        {!isPageOwner && (
                            <div>
                                <span className="mx-1">
                                    <FriendRequestButton
                                        isPageOwner={isPageOwner}
                                        pageOwnerId={id}
                                        friendInfo={friendInfo}
                                        refetch={refetch}
                                    />
                                </span>
                                <span className="mx-1">
                                    <FollowConsumerButton
                                        isPageOwner={isPageOwner}
                                        consumerId={id}
                                        isFollowed={isFollowed}
                                        refetch={refetch}
                                    />
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </main>
    );
});

ConsumerProfileBanner.propTypes = {
    profileImage: PropTypes.string,
    coverImage: PropTypes.string,
    consumerPage: PropTypes.object,
    isPageOwner: PropTypes.bool,
    refetch: PropTypes.func,
};

ConsumerProfileBanner.defaultProps = {
    profileImage: null,
    coverImage: null,
    consumerPage: {},
    isPageOwner: true,
    refetch: () => {},
};

export default ConsumerProfileBanner;
