import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { Skeleton } from 'antd';
import compose from 'lodash.flowright';
import uniqBy from 'lodash/uniqBy';
import {
    AutoSizer,
    CellMeasurer,
    CellMeasurerCache,
    InfiniteLoader,
    List as VList,
    WindowScroller,
} from 'react-virtualized';
import { EmitterContext, FeedEventsNames } from '../../context/emitter-context';
import ConsumerEventActions from '../ConsumerEventActions';
import Error from '../Error/Error';
import FeedPostEvent from '../FeedPostEvent';
import FeedPropertyEvent from '../FeedPropertyEvent';
import LoadingDots from '../LoadingDots';
import AgentPostsConnection from './Operations.graphql';
import './AgentProfilePosts.module.scss';

class AgentProfilePosts extends Component {
    static propTypes = {
        data: PropTypes.oneOfType([PropTypes.object]),
        editable: PropTypes.bool,
        pageId: PropTypes.string,
        isAuthenticated: PropTypes.bool,
    };

    static defaultProps = {
        data: {},
        editable: false,
        pageId: null,
        isAuthenticated: false,
    };

    loadedRowsMap = {};

    constructor(props) {
        super(props);
        this.cache = new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 300,
        });

        this.state = { loadingMore: false };
    }

    componentDidMount() {
        const { emitter } = this.props;
        this.listener = emitter.addListener(FeedEventsNames.ON_POST_ADDED, () => {
            console.log('ON_POST_ADDED');
            this.recalculateHeightAndClearCache();
        });

        this.listener = emitter.addListener(FeedEventsNames.ON_POST_DELETED, () => {
            console.log('ON_POST_DELETED');

            this.recalculateHeightAndClearCache();
        });
    }

    componentWillUnmount() {
        const { emitter } = this.props;
        emitter.removeListener(FeedEventsNames.ON_POST_ADDED, () => {});

        emitter.removeListener(FeedEventsNames.ON_POST_DELETED, () => {});
    }

    handleInfiniteOnLoad = async ({ startIndex, stopIndex }) => {
        const {
            data: { fetchMore, consumerPage },
        } = this.props;
        const { agentPostsConnection } = consumerPage;
        const contributorPosts = uniqBy(agentPostsConnection.nodes, 'id') || [];
        this.setState({ loadingMore: true });
        for (let i = startIndex; i <= stopIndex; i += 1) {
            this.loadedRowsMap[i] = 1;
        }

        if (stopIndex >= contributorPosts.length - 5) {
            return new Promise(async resolve => {
                try {
                    await fetchMore({
                        variables: {
                            filter: {
                                after: consumerPage.agentPostsConnection.pageInfo.endCursor,
                                first: 12,
                            },
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const { nodes } = fetchMoreResult.consumerPage.agentPostsConnection;

                            return nodes.length
                                ? {
                                      consumerPage: {
                                          ...fetchMoreResult.consumerPage,
                                          agentPostsConnection: {
                                              ...fetchMoreResult.consumerPage.agentPostsConnection,
                                              nodes: [
                                                  ...uniqBy(
                                                      previousResult.consumerPage.agentPostsConnection.nodes,
                                                      'id'
                                                  ),
                                                  ...uniqBy(nodes, 'id'),
                                              ],
                                          },
                                      },
                                  }
                                : previousResult;
                        },
                    });
                    resolve(true);
                } catch (e) {
                    console.log(e);
                } finally {
                    this.setState({ loadingMore: false });
                }
            });
        }
    };

    isRowLoaded = ({ index }) => !!this.loadedRowsMap[index];

    parseItem = (event, index) => {
        const { isAuthenticated, pageId } = this.props;
        const { id, uuid, post, property } = event;

        return (
            (property || post) && (
                <div
                    key={id}
                    className="bg-white overflow-hidden text-xs bg-white sm:rounded-lg sm:shadow-sm sm:border-b-0 sm:border-b-transparent border-b border-gray-200 mb-4 consumer-event-card"
                >
                    <div className="pt-4 pb-6">
                        {post && (
                            <FeedPostEvent
                                event={event}
                                onEdit={this.recalculateHeightAndClearCache}
                                pageId={pageId}
                                recalculateHeight={() => this.recalculateHeight(index)}
                            />
                        )}
                        {property && <FeedPropertyEvent event={event} pageId={pageId} />}
                    </div>
                    {isAuthenticated && (
                        <div className="pt-2">
                            <ConsumerEventActions id={id} uuid={uuid} />
                        </div>
                    )}
                </div>
            )
        );
    };

    renderItem = ({ index, key, style, parent }) => {
        const {
            data: { consumerPage },
        } = this.props;
        const { agentPostsConnection } = consumerPage;
        const consumerEvents = uniqBy(agentPostsConnection.nodes, 'id') || [];

        // const { id: loggedInId } = viewer || {};

        const item = consumerEvents[index];
        return (
            <CellMeasurer key={key} cache={this.cache} parent={parent} columnIndex={0} rowIndex={index}>
                <div style={style} key={key}>
                    {this.parseItem(item, index)}
                </div>
            </CellMeasurer>
        );
    };

    recalculateHeightAndClearCache = () => {
        this.cache.clearAll();
        if (this.vlist) {
            this.vlist.recomputeRowHeights();
        }
    };

    recalculateHeight = index => {
        this.cache.clearAll();
        if (this.vlist) {
            this.vlist.recomputeRowHeights(index);
        }
    };

    render() {
        const {
            data: { loading, consumerPage, error },
            editable,
        } = this.props;
        const { loadingMore } = this.state;

        if (loading) {
            return (
                <div className="mt-3">
                    {Array(...Array(3)).map((v, i) => (
                        <Skeleton
                            loading
                            active
                            avatar={{ size: 52 }}
                            paragraph={{ rows: 2 }}
                            className="bg-white rounded-lg mb-4 p-4 sm:shadow-sm consumer-event-skeleton sm:border-b-0 border-b border-gray-200"
                            key={i.toString()}
                        />
                    ))}
                </div>
            );
        }

        if (error) return <Error error={error} />;

        const { agentPostsConnection } = consumerPage;
        const consumerEvents = uniqBy(agentPostsConnection.nodes, 'id') || [];
        const loadMore = loadingMore && <LoadingDots />;

        return (
            <div className="mt-3">
                {consumerEvents.length > 0 ? (
                    <>
                        <WindowScroller>
                            {({ height, isScrolling, onChildScroll, scrollTop }) => (
                                <InfiniteLoader
                                    isRowLoaded={this.isRowLoaded}
                                    loadMoreRows={this.handleInfiniteOnLoad}
                                    rowCount={consumerEvents.length}
                                >
                                    {({ onRowsRendered }) => (
                                        <AutoSizer disableHeight>
                                            {({ width }) => (
                                                <VList
                                                    autoHeight
                                                    height={height}
                                                    isScrolling={isScrolling}
                                                    onScroll={onChildScroll}
                                                    overscanRowCount={2}
                                                    rowCount={consumerEvents.length}
                                                    rowHeight={this.cache.rowHeight}
                                                    // rowHeight={73}
                                                    rowRenderer={this.renderItem}
                                                    onRowsRendered={onRowsRendered}
                                                    deferredMeasurementCache={this.cache}
                                                    scrollTop={scrollTop}
                                                    width={width}
                                                    ref={ref => {
                                                        this.vlist = ref;
                                                    }}
                                                    className="consumer-profile-events-list"
                                                />
                                            )}
                                        </AutoSizer>
                                    )}
                                </InfiniteLoader>
                            )}
                        </WindowScroller>
                        {!agentPostsConnection.pageInfo.hasNextPage && (
                            <p className="text-center text-gray-500">You&apos;ve reached the end</p>
                        )}
                    </>
                ) : (
                    <div className="text-center h-64 bg-white rounded-lg shadow relative">
                        <h2 className="pt-2 pb-1 text-gray-700 center-align">
                            {editable
                                ? 'Build your own feed, start following agents, agencies & contributors now'
                                : 'No posts yet'}
                        </h2>
                    </div>
                )}
                {loadMore}
            </div>
        );
    }
}

const AgentProfilePostsList = compose(
    graphql(AgentPostsConnection, {
        options: ({ pageId }) => ({
            ssr: false,
            variables: {
                pageId,
                filter: { first: 20 },
            },
        }),
    })
)(AgentProfilePosts);

const AgentProfilePostsComponent = props => (
    <EmitterContext.Consumer>
        {emitter => <AgentProfilePostsList emitter={emitter} {...props} />}
    </EmitterContext.Consumer>
);

export default AgentProfilePostsComponent;
