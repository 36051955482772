/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { Card, Tag } from 'antd';
import compose from 'lodash.flowright';
import Error from '../Error';
import FollowCardTitleCount from '../FollowCardTitleCount';
import { FollowedSuburbQuery } from './Operations.graphql';

class ConsumerProfileFollowingSuburbList extends PureComponent {
    static propTypes = { data: PropTypes.object };

    static defaultProps = { data: {} };

    render() {
        const {
            data: { loading, error, consumerPage },
        } = this.props;

        if (error) return <Error error={error} />;

        const { owner } = consumerPage || {};
        const { followedSuburbsConnection } = owner || {};
        const followedSuburbs = followedSuburbsConnection ? followedSuburbsConnection.nodes : [];

        return (
            <Card
                loading={loading}
                bordered={false}
                bodyStyle={{ padding: 0 }}
                className="shadow rounded"
                title={<FollowCardTitleCount title="Suburbs" count={followedSuburbs.length} />}
            >
                <main className="flex flex-wrap py-3 px-6">
                    {followedSuburbs.length > 0 ? (
                        <>
                            {followedSuburbs.map(({ id, full }) => (
                                <Tag
                                    className="font-bold mb-2"
                                    style={{
                                        height: 'auto',
                                        whiteSpace: 'normal',
                                    }}
                                    color="#26C6F1"
                                    key={id}
                                >
                                    {full}
                                </Tag>
                            ))}
                        </>
                    ) : (
                        <span>No suburbs yet</span>
                    )}
                </main>
            </Card>
        );
    }
}

export default compose(
    graphql(FollowedSuburbQuery, {
        options: ({ pageId }) => ({
            variables: {
                pageId,
                filter: { first: 100 },
            },
        }),
    })
)(ConsumerProfileFollowingSuburbList);
