import { gql } from '@apollo/client';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { graphql } from '@apollo/client/react/hoc';
import { PlusOutlined } from '@ant-design/icons';
import { Avatar, Card } from 'antd';
import compose from 'lodash.flowright';
import Error from '~/components/Error';
import FollowCardTitleCount from '~/components/FollowCardTitleCount';

import { FollowedContributorQuery } from './Operations.graphql';
import PRIMARY from '~/constants/colors';

class ConsumerProfileFollowersList extends PureComponent {
    static propTypes = {
        data: PropTypes.object,
        editable: PropTypes.bool,
    };

    static defaultProps = {
        data: {},
        editable: null,
    };

    render() {
        const {
            data: { loading, error, consumerPage },
            editable,
        } = this.props;
        if (error) return <Error error={error} />;

        const { owner } = consumerPage || {};
        const { followersConnection } = owner || {};
        const followedConsumers = followersConnection ? followersConnection.nodes : [];

        return (
            <Card
                loading={loading}
                bodyStyle={{ padding: 0 }}
                bordered={false}
                className="rounded shadow"
                title={<FollowCardTitleCount title="Followers" count={followedConsumers.length} />}
                extra={
                    editable && (
                        <Link href="/lifestyle" className="rounded-full p-2">
                            <PlusOutlined />
                        </Link>
                    )
                }
            >
                <main className="flex flex-wrap px-6 py-3">
                    {followedConsumers.length > 0 ? (
                        <>
                            {followedConsumers.map(
                                ({ id, initials, firstName, lastName, profilePageId, profilePicture }) => {
                                    const firstLetter = initials.charAt(0).toLowerCase();
                                    return (
                                        <Link key={profilePageId} href={`/${profilePageId || ''}`}>
                                            <div className="flex cursor-pointer align-middle">
                                                <Avatar
                                                    src={profilePicture}
                                                    className={`contributor-avatar mb-1 mr-1 mt-1 bg-white font-bold avatar-${firstLetter}`}
                                                    key={id}
                                                    size={50}
                                                    title={`${firstName}${' '}${lastName}`}
                                                    style={{ border: `2px solid ${PRIMARY}`, color: PRIMARY }}
                                                >
                                                    {initials}
                                                </Avatar>
                                                <div className="ml-2 flex items-center text-gray-900">
                                                    <div>
                                                        <div className="font-semibold">
                                                            {firstName} {lastName}
                                                        </div>

                                                        <span className="inline-block text-xs font-normal">
                                                            @{profilePageId}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    );
                                }
                            )}
                        </>
                    ) : (
                        <span>No followers yet</span>
                    )}
                </main>
            </Card>
        );
    }
}

const CONSUMER_FOLLOW_CONTRIBUTOR = gql`
    mutation consumerFollowConsumer($followeeId: ID!) {
        consumerFollowConsumer(followeeId: $followeeId)
    }
`;
const CONSUMER_UNFOLLOW_CONTRIBUTOR = gql`
    mutation consumerUnfollowConsumer($followeeId: ID!) {
        consumerUnfollowConsumer(followeeId: $followeeId)
    }
`;

export default compose(
    graphql(FollowedContributorQuery, {
        options: ({ pageId }) => ({
            variables: {
                pageId,
                filter: { first: 7 },
            },
        }),
    }),
    graphql(CONSUMER_FOLLOW_CONTRIBUTOR, {
        name: 'consumerFollowConsumer',
        props: ({ consumerFollowConsumer }) => ({
            consumerFollowConsumer: (followeeId) => consumerFollowConsumer({ variables: { followeeId } }),
        }),
    }),
    graphql(CONSUMER_UNFOLLOW_CONTRIBUTOR, {
        name: 'consumerUnfollowConsumer',
        props: ({ consumerUnfollowConsumer }) => ({
            consumerUnfollowConsumer: (followeeId) => consumerUnfollowConsumer({ variables: { followeeId } }),
        }),
    })
)(ConsumerProfileFollowersList);
