/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { Col, Menu, Row } from 'antd';
import PublicLayout from '~/layouts/PublicLayout/PublicLayout';
import useWindowSize from '~/utils/useWindowSize';
import AgentAbout from '~/components/AgentAbout/AgentAbout';
import AgentProfileBanner from '~/components/AgentProfileBanner';
import AgentProfileBannerMobile from '~/components/AgentProfileBannerMobile';
import AgentProfilePosts from '~/components/AgentProfilePosts';
import AgentProperties from '~/components/AgentProperties';
import ConsumerPageSeoHeader from '~/components/ConsumerPageSeoHeader';
import ConsumerProfileAbout from '~/components/ConsumerProfileAbout';
import ConsumerProfileFollowersList from '~/components/ConsumerProfileFollowersList';
import ConsumerProfileFollowingContributorList from '~/components/ConsumerProfileFollowingContributorList';
import ConsumerProfileFollowingPageList from '~/components/ConsumerProfileFollowingPageList';
import ConsumerProfileFollowingPropertyList from '~/components/ConsumerProfileFollowingPropertyList';
import ConsumerProfileFollowingSuburbList from '~/components/ConsumerProfileFollowingSuburbList';
import ConsumerProfileFriendList from '~/components/ConsumerProfileFriendList';
import ConsumerProfilePostForm from '~/components/ConsumerProfilePostForm';
import InviteAFriendButton from '~/components/InviteAFriendButton';
import './AgentProfilePage.module.scss';

const AgentProfilePage = (props) => {
    const {
        consumerPage,
        refetch,
        router: {
            query: { subRoute },
        },
    } = props;

    const { pageId, isPageOwner } = consumerPage || {};
    const { width } = useWindowSize();

    return (
        <PublicLayout fixedHeader showFooter={false}>
            <div className="bg-gray-200" style={{ minHeight: '210vh' }}>
                <ConsumerPageSeoHeader consumerPage={consumerPage} />
                <div className="AgentProfilePage">
                    <main className="bg-white sm:bg-transparent">
                        {width > 600 ? (
                            <AgentProfileBanner consumerPage={consumerPage} refetch={refetch} />
                        ) : (
                            <AgentProfileBannerMobile consumerPage={consumerPage} refetch={refetch} />
                        )}
                    </main>
                    <Menu
                        mode="horizontal"
                        className="sub-menu mt-3"
                        defaultSelectedKeys={['feed']}
                        selectedKeys={[subRoute || 'feed']}
                        style={{ fontSize: '14px' }}
                    >
                        <Menu.Item key="feed">
                            <Link href={`/${pageId}`}>Posts</Link>
                        </Menu.Item>
                        <Menu.Item key="about">
                            <Link href={`/${pageId}/about`}>About</Link>
                        </Menu.Item>
                        <Menu.Item key="properties">
                            <Link href={`/${pageId}/properties`}>Properties</Link>
                        </Menu.Item>
                    </Menu>
                    {!subRoute && (
                        <Row gutter={{ md: 24 }} className="h-full" justify="center">
                            <Col xs={0} md={9}>
                                <div className="pt-3">
                                    <InviteAFriendButton />
                                </div>
                                <ConsumerProfileAbout consumerPage={consumerPage} refetch={refetch} />
                                <div className="pt-3">
                                    <ConsumerProfileFollowersList pageId={pageId} editable={isPageOwner} />
                                </div>
                                <ConsumerProfileFriendList pageId={pageId} editable={isPageOwner} />
                                <div className="pt-3">
                                    <ConsumerProfileFollowingContributorList pageId={pageId} editable={isPageOwner} />
                                </div>
                                <div className="pt-3">
                                    <ConsumerProfileFollowingPageList pageId={pageId} editable={isPageOwner} />
                                </div>
                                <div className="pt-3">
                                    <ConsumerProfileFollowingPropertyList pageId={pageId} editable={isPageOwner} />
                                </div>
                                <div className="pt-3">
                                    <ConsumerProfileFollowingSuburbList pageId={pageId} editable={isPageOwner} />
                                </div>
                            </Col>
                            <Col xs={24} md={15}>
                                {isPageOwner && <ConsumerProfilePostForm pageId={pageId} editable={isPageOwner} />}
                                <AgentProfilePosts pageId={pageId} editable={isPageOwner} />
                            </Col>
                        </Row>
                    )}
                    {subRoute === 'about' && <AgentAbout page={consumerPage} pageId={pageId} editable={isPageOwner} />}
                    {subRoute === 'properties' && (
                        <AgentProperties page={consumerPage} agentId={consumerPage.agent.id} />
                    )}
                </div>
            </div>
        </PublicLayout>
    );
};

AgentProfilePage.propTypes = {
    consumerPage: PropTypes.object,
    refetch: PropTypes.func,
    router: PropTypes.shape({ query: PropTypes.object }),
};

AgentProfilePage.defaultProps = {
    consumerPage: {},
    refetch: () => {},
    router: {},
};

export default withRouter(AgentProfilePage);
