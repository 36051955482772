/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { Affix, Button, Col, Menu, Row } from 'antd';
import has from 'lodash/has';
import PublicLayout from '~/layouts/PublicLayout';
import BusinessPageRoutes from '~/components/BusinessPageRoutes';
import BusinessSocial from '~/components/BusinessSocial';
import BusinessUploadCoverPhoto from '~/components/BusinessUploadCoverPhoto';
import BusinessUploadProfilePhoto from '~/components/BusinessUploadProfilePhoto';
import ConsumerPageSeoHeader from '~/components/ConsumerPageSeoHeader';
import NotFound from '~/components/NotFound';
import ScreenWidthValueProvider from '~/components/ScreenWidthValueProvider';
import styles from './BusinessPage.module.scss';

const BusinessPage = ({ consumerPage, isAuthenticated, refetch, router }) => {
    const changeSubPage = ({ key }) => {
        window.scrollTo(0, 0);
        if (key === 'home') {
            router.push(`/${router.query.pageId}`);
        } else {
            router.push(`/${router.query.pageId}/${key}`);
        }
    };

    const { id, pageId, name, profileImageS3, profileImage, coverImage, isActive, isPageOwner, isOfficial } =
        consumerPage || {};

    if (!isPageOwner && !isActive) return <NotFound />;

    return (
        <PublicLayout>
            <ConsumerPageSeoHeader consumerPage={consumerPage} />
            <div className="sm:px-3 sm:py-2 md:py-4 lg:py-8" style={{ minHeight: '130vh' }}>
                <Row
                    justify="center"
                    gutter={{
                        xs: 0,
                        md: 0,
                        lg: 16,
                    }}
                >
                    <ScreenWidthValueProvider xl xxl>
                        {(matches) =>
                            matches ? (
                                <>
                                    <Col className="mb-2 self-start">
                                        <div className={styles.businessSideContent}>
                                            <Affix offsetTop={30} className="wrap">
                                                <div>
                                                    <div>
                                                        <BusinessUploadProfilePhoto
                                                            consumerPageId={id}
                                                            profileImageS3={profileImageS3}
                                                            profileImage={profileImage}
                                                            isPageOwner={isPageOwner}
                                                            refetch={refetch}
                                                        />
                                                    </div>
                                                    <div className="mb-3 text-center">
                                                        <h3 className="mb-1  font-semibold">{name}</h3>
                                                        <h4 className="font-semibold">
                                                            {isOfficial ? '(Official)' : '(Unofficial)'}
                                                        </h4>
                                                        <div className="mt-1 break-all text-sm font-bold text-slate-500">{`@${pageId}`}</div>
                                                    </div>
                                                    <div>
                                                        <Menu
                                                            className={`${styles.businessPageMenu}`}
                                                            selectedKeys={[
                                                                has(router.query, 'subRoute')
                                                                    ? router.query.subRoute
                                                                    : 'home',
                                                            ]}
                                                            mode="vertical"
                                                            onClick={changeSubPage}
                                                        >
                                                            <Menu.Item key="home">Home</Menu.Item>
                                                            <Menu.Item key="about">About</Menu.Item>
                                                            <Menu.Item key="posts">Posts</Menu.Item>
                                                            <Menu.Item key="photos">Photos</Menu.Item>
                                                        </Menu>
                                                        <div className="mt-3 text-center">
                                                            <Link
                                                                href="/business-settings"
                                                                as="/business-settings"
                                                                legacyBehavior
                                                            >
                                                                <Button type="primary" className="font-bold">
                                                                    {isPageOwner ? 'Edit Page' : 'Create a Page'}
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Affix>
                                        </div>
                                    </Col>
                                    <Col className="self-start">
                                        <div className={styles.businessMainContent}>
                                            <BusinessUploadCoverPhoto
                                                consumerPageId={id}
                                                coverImage={coverImage}
                                                isPageOwner={isPageOwner}
                                                refetch={refetch}
                                            />
                                            <BusinessPageRoutes
                                                isPageOwner={isPageOwner}
                                                isAuthenticated={isAuthenticated}
                                                consumerPage={consumerPage}
                                                refetch={refetch}
                                                changeSubPage={changeSubPage}
                                                subRoute={
                                                    has(router.query, 'subRoute') ? router.query.subRoute : 'home'
                                                }
                                            />
                                        </div>
                                    </Col>
                                </>
                            ) : (
                                ''
                            )
                        }
                    </ScreenWidthValueProvider>
                    <ScreenWidthValueProvider xs sm md lg defaultMatches={false}>
                        {(matches) =>
                            matches ? (
                                <Col xs={24} md={18}>
                                    <BusinessUploadCoverPhoto
                                        consumerPageId={id}
                                        coverImage={coverImage}
                                        isPageOwner={isPageOwner}
                                        refetch={refetch}
                                    />
                                    <div className="border border-solid border-gray-300 p-3 sm:p-6">
                                        <BusinessUploadProfilePhoto
                                            consumerPageId={id}
                                            profileImageS3={profileImageS3}
                                            profileImage={profileImage}
                                            isPageOwner={isPageOwner}
                                            refetch={refetch}
                                        />
                                        <div className="pageName text-center">
                                            <div className="mb-3">
                                                <h3>{name}</h3>
                                                <h4>{isOfficial ? '(Official)' : '(Unofficial)'}</h4>
                                                <h5 className="text-sm text-slate-500">{`@${pageId}`}</h5>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-center">
                                            <BusinessSocial
                                                consumerPage={consumerPage}
                                                refetch={refetch}
                                                isAuthenticated={isAuthenticated}
                                            />
                                            <div className="hidden pl-2 sm:block">
                                                <Link href="/business-settings" as="/business-settings" legacyBehavior>
                                                    <Button
                                                        style={{
                                                            width: '100%',
                                                            borderRadius: '9999px',
                                                            fontWeight: '700',
                                                        }}
                                                        // className="rounded-full font-bold"
                                                        type="primary"
                                                    >
                                                        {isPageOwner ? 'Edit Page' : 'Create a Page'}
                                                    </Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`${styles.businessPageMenuWrapper} relative mb-5
                                        overflow-x-auto overflow-y-hidden border-b border-l border-r border-solid
                                        border-gray-300 text-center sm:static sm:overflow-x-hidden`}
                                    >
                                        <Menu
                                            style={{ borderBottom: 0 }}
                                            className="businessPageMenu border-none sm:static"
                                            selectedKeys={[
                                                has(router.query, 'subRoute') ? router.query.subRoute : 'home',
                                            ]}
                                            mode="horizontal"
                                            onClick={changeSubPage}
                                        >
                                            <Menu.Item key="home">Home</Menu.Item>
                                            <Menu.Item key="about">About</Menu.Item>
                                            <Menu.Item key="posts">Posts</Menu.Item>
                                            <Menu.Item key="photos">Photos</Menu.Item>
                                        </Menu>
                                    </div>
                                    <BusinessPageRoutes
                                        isPageOwner={isPageOwner}
                                        isAuthenticated={isAuthenticated}
                                        consumerPage={consumerPage}
                                        refetch={refetch}
                                        subRoute={has(router.query, 'subRoute') ? router.query.subRoute : 'home'}
                                    />
                                </Col>
                            ) : (
                                ''
                            )
                        }
                    </ScreenWidthValueProvider>
                </Row>
            </div>
        </PublicLayout>
    );
};

BusinessPage.propTypes = {
    consumerPage: PropTypes.shape({
        id: PropTypes.string,
        pageId: PropTypes.string,
        name: PropTypes.string,
        profileImageS3: PropTypes.string,
        profileImage: PropTypes.string,
        coverImage: PropTypes.string,
        isActive: PropTypes.bool,
        isPageOwner: PropTypes.bool,
        isOfficial: PropTypes.bool,
    }),
    refetch: PropTypes.func,
    router: PropTypes.shape({
        push: PropTypes.func,
        query: PropTypes.object,
    }),
    isAuthenticated: PropTypes.bool,
};

BusinessPage.defaultProps = {
    consumerPage: {},
    refetch: () => {},
    router: {},
    isAuthenticated: false,
};

export default withRouter(BusinessPage);
