import { useApolloClient, useMutation } from '@apollo/client';
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { EnvironmentFilled } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, Radio, Select, Spin } from 'antd';
import { faCube, faImages, faPenSquare, faVideo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import getUrls from 'get-urls';
import compose from 'lodash.flowright';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import BusinessPostAvatar from '~/components/BusinessPostAvatar';
import ConsumerPageEventsConnection from '~/components/BusinessPosts/Operations.graphql';
import ConsumerPostImageForm from '~/components/ConsumerPostImageForm';
import ConsumerPostMeta from '~/components/ConsumerPostMeta';
import { addRecordToCache } from '~/utils/graphql';
import LocationSuggestionsQuery from '~/queries/LocationSuggestionsQuery.graphql';
import { CreateConsumerPagePostMutation, SiteMetaQuery } from './Operations.graphql';
import PRIMARY from '~/constants/colors';
import { FeedEventsNames, withEmitterContext } from '~/context/emitter-context';
import styles from './BusinessPostForm.module.scss';

const { Option } = Select;

const BusinessPostForm = ({
    consumerPageId,
    pageId,
    refetch,
    form: { getFieldDecorator, getFieldValue, validateFieldsAndScroll, resetFields },
    emitter,
}) => {
    const client = useApolloClient();
    const [placeholder, setPlaceholder] = useState('Share your contribution');
    const [type, setType] = useState('review');
    const [isPasting, setIsPasting] = useState(true);
    const [metas, setMetas] = useState(null);
    const [formShadow, setFormShadow] = useState('sm:shadow-sm');
    const [postButtonDisplay, setPostButtonDisplay] = useState(styles.hideBtn);
    const [postButtonDisabled, setPostButtonDisabled] = useState(true);
    const [postText, setPostText] = useState('');
    const [fileList, setFileList] = useState([]);
    const [imageKeys, setImageKeys] = useState([]);
    const [showCheckInBox, setShowCheckInBox] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [dataSource, setDataSource] = useState([]);

    const wrapperRef = useRef();

    const [createConsumerPagePost, { loading }] = useMutation(CreateConsumerPagePostMutation, {
        update: (store, { data: { createConsumerPagePost: createConsumerPagePostData } }) => {
            try {
                const variables = {
                    pageId,
                    consumerPageId,
                    filter: { first: 20 },
                };

                addRecordToCache(store, ConsumerPageEventsConnection, variables, (data) => ({
                    ...data,
                    consumerPage: {
                        ...data.consumerPage,
                        consumerPageEventsConnection: {
                            ...data.consumerPage.consumerPageEventsConnection,
                            nodes: [createConsumerPagePostData, ...data.consumerPage.consumerPageEventsConnection],
                        },
                    },
                }));

                emitter.emit(FeedEventsNames.ON_POST_ADDED);
            } catch (err) {
                console.log(err);
            }
        },
    });

    const onClickOutsidePostForm = (event) => {
        if (wrapperRef && wrapperRef.current.contains(event.target)) {
            return;
        }
        setFormShadow('sm:shadow-sm');
        setPostButtonDisplay(stlyes.hideBtn);
    };

    useEffect(() => {
        document.addEventListener('mousedown', onClickOutsidePostForm);

        return function cleanup() {
            document.removeEventListener('mousedown', onClickOutsidePostForm);
        };
    }, []);

    const handleChange = (e) => {
        const { value } = e.target;
        const newPlaceholder =
            value === 'review' || value === 'image' ? 'Share your contribution' : `Add link for your ${value}`;

        let postButtonStatus;
        switch (value) {
            case 'review':
                postButtonStatus = isEmpty(metas) && isEmpty(trim(postText));
                break;
            case 'image':
                postButtonStatus = isEmpty(imageKeys) && isEmpty(trim(postText));
                break;
            default:
                postButtonStatus = true;
                break;
        }

        setPlaceholder(newPlaceholder);
        setType(value);
        setPostButtonDisabled(postButtonStatus);
    };

    const onPaste = () => {
        setIsPasting(true);
    };

    const onChangeMeta = () => {
        setPostButtonDisabled(isEmpty(metas) && isEmpty(trim(postText)));
    };

    const onRemoveMeta = () => {
        setMetas(null);
        onChangeMeta();
    };

    const onRemoveImage = () => {
        setMetas(Object.assign({}, metas, { image: null }));
    };

    const queryMetaTags = async (url) => {
        try {
            const {
                data: { siteMeta },
            } = await client.query({
                query: SiteMetaQuery,
                variables: { url },
            });
            setMetas(siteMeta || null);
        } catch (error) {
            console.log(error);
        }
    };

    const onChange = (e) => {
        if (isPasting) {
            const urls = getUrls(e.target.value);
            if (urls.size > 0) {
                const setIter = urls.values();

                const url = setIter.next().value;
                queryMetaTags(url);
                setIsPasting(false);
            }
        }

        let postButtonStatus;
        switch (type) {
            case 'review':
                postButtonStatus = isEmpty(metas) && isEmpty(trim(e.target.value));
                break;
            case 'image':
                postButtonStatus = isEmpty(imageKeys) && isEmpty(trim(e.target.value));
                break;
            default:
                postButtonStatus = true;
                break;
        }

        setPostButtonDisabled(postButtonStatus);
        setPostText(e.target.value);
    };

    const onClickPostForm = () => {
        setFormShadow('sm:shadow-md');
        setPostButtonDisplay(styles.showBtn);
    };

    const onUploadFocus = () => {
        setPostButtonDisplay(styles.showBtn);
    };

    const onUploadImage = (value) => {
        setPostButtonDisabled(isEmpty(postText) && value);
        setImageKeys(getFieldValue('images'));
    };

    const onStoreImageKeys = (newFileList) => {
        setFileList(newFileList);
    };

    const handleCheckInBox = () => {
        setShowCheckInBox(!showCheckInBox);
    };

    const handleSearch = async (value) => {
        const keyword = value;

        setIsSearching(true);

        try {
            const {
                data: { locationSuggestions },
            } = await client.query({
                query: LocationSuggestionsQuery,
                variables: {
                    filter: {
                        keyword,
                        first: 10,
                    },
                },
            });
            setIsSearching(false);
            setDataSource(locationSuggestions || []);
        } catch (error) {
            console.log(error);
        }
    };

    const onSearch = useCallback(
        debounce((value) => handleSearch(value), 300),
        []
    );

    const handleSubmit = (e) => {
        message.config({ top: 24 });
        e.preventDefault();

        validateFieldsAndScroll((err, values) => {
            if (!err) {
                const postInputValues = {
                    ...values,
                    consumerPageId,
                };

                if (metas) {
                    Object.assign(postInputValues, {
                        metaHost: metas.host || null,
                        metaUrl: metas.url || null,
                        metaDescription: metas.description || null,
                        metaImage: metas.image || null,
                        metaTitle: metas.title || null,
                    });
                } else {
                    Object.assign(postInputValues, {
                        metaHost: null,
                        metaUrl: null,
                        metaDescription: null,
                        metaImage: null,
                        metaTitle: null,
                    });
                }

                if (type === 'image') {
                    if (isEmpty(values.images) && !isEmpty(imageKeys)) {
                        Object.assign(postInputValues, { images: imageKeys });
                    }
                }

                createConsumerPagePost({ variables: { input: postInputValues } })
                    .then(() => {
                        message.destroy();
                        message.success('Successfully posted');
                        setMetas(null);
                        resetFields();
                    })
                    .catch((error) => {
                        message.destroy();
                        message.error('Something went wrong');
                        console.log(error);
                    })
                    .finally(() => {
                        setFileList([]);
                        setPostText('');
                        setImageKeys([]);
                        setType('review');
                        setPostButtonDisabled(true);
                        setPostButtonDisplay(styles.hideBtn);
                        setShowCheckInBox(false);
                        refetch();
                    });
            }
        });
    };

    const renderOptions = (defaultSource = []) => {
        const filteredResult = defaultSource.filter((suburb) => suburb !== null);

        return (
            filteredResult.length &&
            filteredResult.map((suburb) => (
                <Option key={suburb.id} className="py-3" value={suburb.full}>
                    <span className="text-base">{suburb.full}</span>
                </Option>
            ))
        );
    };

    const checkInBoxStyle = showCheckInBox ? styles.showDropDown : styles.hideDropDown;

    return (
        <main
            ref={wrapperRef}
            className={`p-4 ${formShadow} ${styles['consumer-content-form']} mb-4 transition sm:rounded-lg`}
            onClick={onClickPostForm}
        >
            <Form onSubmit={handleSubmit}>
                <Form.Item className="content-type mb-0">
                    {getFieldDecorator('type', { initialValue: type })(
                        <Radio.Group buttonStyle="solid" className="content-type-radio" onChange={handleChange}>
                            <Radio.Button value="review" className="border-none">
                                <FA icon={faPenSquare} />
                            </Radio.Button>
                            <Radio.Button value="image" className="border-none">
                                <FA icon={faImages} />
                            </Radio.Button>
                            <Radio.Button value="video" className="border-none" disabled title="Coming">
                                <FA icon={faVideo} />
                            </Radio.Button>
                            <Radio.Button value="3d" className="border-none" disabled title="Coming">
                                <FA icon={faCube} />
                            </Radio.Button>
                        </Radio.Group>
                    )}
                </Form.Item>
                {type !== 'image' ? (
                    <>
                        <Form.Item className="mb-0">
                            <div className="flex rounded rounded-tl-none bg-white">
                                <div className="pt-2 pl-2">
                                    <BusinessPostAvatar pageId={pageId} />
                                </div>
                                {getFieldDecorator('content')(
                                    <Input.TextArea
                                        onChange={onChange}
                                        onPaste={onPaste}
                                        className="content-textarea rounded-tl-none border transition"
                                        placeholder={placeholder}
                                        autoSize={{ minRows: 3 }}
                                    />
                                )}
                            </div>
                        </Form.Item>
                        {metas && (
                            <ConsumerPostMeta
                                {...metas}
                                editable
                                onRemoveMeta={onRemoveMeta}
                                onRemoveImage={onRemoveImage}
                            />
                        )}
                    </>
                ) : (
                    <>
                        <Form.Item className="mb-0">
                            <div className="flex rounded-tr bg-white">
                                <div className="pt-2 pl-2">
                                    <BusinessPostAvatar pageId={pageId} />
                                </div>
                                <div className="flex-1">
                                    {getFieldDecorator('content')(
                                        <Input.TextArea
                                            onChange={onChange}
                                            onPaste={onPaste}
                                            className="content-textarea rounded-tl-none border-none transition"
                                            placeholder="Share your contribution"
                                            autoSize={{ minRows: 3 }}
                                        />
                                    )}
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item className="mb-0">
                            {getFieldDecorator('images')(
                                <ConsumerPostImageForm
                                    keys={imageKeys}
                                    fileList={fileList}
                                    onUploadFocus={onUploadFocus}
                                    onUploadImage={onUploadImage}
                                    onStoreImageKeys={onStoreImageKeys}
                                />
                            )}
                        </Form.Item>
                    </>
                )}
                <div className={`${styles.CheckInBox} ${checkInBoxStyle} flex items-center`}>
                    <div
                        className="rounded-tl rounded-bl text-base text-white"
                        style={{
                            padding: '8px 12px',
                            backgroundColor: PRIMARY,
                        }}
                    >
                        <EnvironmentFilled />
                    </div>
                    <div className="flex-1">
                        {getFieldDecorator('checkIn')(
                            <Select
                                showSearch
                                className="w-full"
                                placeholder="Search suburb"
                                optionFilterProp="children"
                                filterOption={false}
                                size="large"
                                showArrow={false}
                                notFoundContent={isSearching ? <Spin size="small" /> : null}
                                onSearch={onSearch}
                                loading={isSearching}
                                allowClear
                            >
                                {renderOptions(dataSource)}
                            </Select>
                        )}
                    </div>
                </div>
                <div className={`${styles.PostFormBtnWrapper} pt-2 text-right ${postButtonDisplay}`}>
                    <Button className="mr-2 text-sm" onClick={handleCheckInBox}>
                        Check-In
                    </Button>
                    <Button
                        className="text-sm"
                        type="primary"
                        loading={loading}
                        htmlType="submit"
                        disabled={postButtonDisabled}
                    >
                        Post
                    </Button>
                </div>
            </Form>
        </main>
    );
};

BusinessPostForm.propTypes = {
    form: PropTypes.object,
    refetch: PropTypes.func,
    consumerPageId: PropTypes.string,
    pageId: PropTypes.string,
    emitter: PropTypes.object,
};

BusinessPostForm.defaultProps = {
    form: {},
    refetch: () => {},
    consumerPageId: null,
    pageId: null,
    emitter: {},
};

export default compose(Form.create(), withEmitterContext)(BusinessPostForm);
