import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, message, Modal, Popconfirm } from 'antd';
import compose from 'lodash.flowright';
import { connect } from 'react-redux';
import { loginUser } from '~/actions';
import GuestLoginForm from '../GuestLoginForm';
import { ClaimAgentPageMutation } from './Operations.graphql';

const ClaimAgentSocialButton = ({
    isAuthenticated,
    userType,
    onLoginUser,
    isClaimed,
    refetch,
    consumerId,
    isPageOwner,
    consumerPageId,
}) => {
    const [consumerLoginDecisionModal, setConsumerLoginDecisionModal] = useState(false);
    const [claimAgentPage, { loading }] = useMutation(ClaimAgentPageMutation);

    const toggleClaimButton = async () => {
        claimAgentPage({ variables: { consumerPageId } }).then(result => {
            if (result.data.claimAgentPage) {
                message.loading('Page claimed. Syncing data...');
                refetch();
            } else {
                message.error('Something went wrong');
            }
        });
    };

    const showDecisionModal = bool => {
        setConsumerLoginDecisionModal(bool);
    };

    const closeConsumerLoginModal = () => {
        setConsumerLoginDecisionModal(false);
    };

    const handleClaimButtonClick = () => {
        if (isAuthenticated) {
            toggleClaimButton();
        } else {
            showDecisionModal(true);
        }
    };

    const onLoginSuccess = loginData => {
        const { accessToken, expiresIn, refreshToken, user, type } = loginData;
        onLoginUser({
            accessToken,
            expiresIn,
            refreshToken,
            user,
            type,
        });

        if (user.id !== consumerId && !isClaimed) {
            claimAgentPage({
                variables: {
                    consumerId,
                    consumerPageId,
                },
            }).then(result => {
                if (result.data.claimAgentPage) {
                    message.loading('Page claimed. Syncing data...');
                    refetch();
                } else {
                    message.error('Something went wrong');
                }
            });
        }
        closeConsumerLoginModal();
    };

    if (isPageOwner || isClaimed) return null;

    return (
        <div className="inline-block">
            <div className="relative -mt-4">
                {isAuthenticated && userType !== 'CONSUMER' && <span />}
                {!isAuthenticated && (
                    <Popconfirm
                        placement="bottomRight"
                        title="Is this your page?"
                        onConfirm={handleClaimButtonClick}
                        okText="Yes"
                        cancelText="No"
                        icon={null}
                    >
                        <Button type="primary" loading={loading}>
                            Claim
                        </Button>
                    </Popconfirm>
                )}
                {isAuthenticated && userType === 'CONSUMER' && (
                    <Popconfirm
                        placement="bottomRight"
                        title="Is this your page?"
                        onConfirm={handleClaimButtonClick}
                        okText="Yes"
                        cancelText="No"
                        icon={null}
                    >
                        <Button type="primary" loading={loading}>
                            Claim
                        </Button>
                    </Popconfirm>
                )}
            </div>
            <Modal
                centered
                visible={consumerLoginDecisionModal}
                onOk={() => showDecisionModal(false)}
                onCancel={() => showDecisionModal(false)}
                footer={null}
                zIndex={1060}
            >
                <GuestLoginForm onSuccess={onLoginSuccess} onSuccessAccount={onLoginSuccess} />
            </Modal>
        </div>
    );
};

ClaimAgentSocialButton.propTypes = {
    userType: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    onLoginUser: PropTypes.func,
    isClaimed: PropTypes.bool,
    refetch: PropTypes.func,
    consumerId: PropTypes.string,
    isPageOwner: PropTypes.bool,
    consumerPageId: PropTypes.string,
};

ClaimAgentSocialButton.defaultProps = {
    userType: null,
    isAuthenticated: false,
    onLoginUser: () => {},
    isClaimed: false,
    refetch: () => {},
    consumerId: null,
    isPageOwner: false,
    consumerPageId: null,
};

export default compose(
    connect(
        state => ({
            isAuthenticated: state.auth.isAuthenticated,
            userType: state.auth.type,
        }),
        dispatch => ({
            onLoginUser(accessToken) {
                dispatch(loginUser(accessToken));
            },
        })
    )
)(ClaimAgentSocialButton);
