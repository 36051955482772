/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { CurrentConsumer } from './Operations.graphql';
import PRIMARY from '~/constants/colors';

class ConsumerProfilePostAvatar extends PureComponent {
    render() {
        const {
            data: { loading, viewer },
        } = this.props;

        if (loading) {
            return (
                <Avatar
                    style={{
                        color: PRIMARY,
                        backgroundColor: '#fff',
                        border: `2px solid ${PRIMARY}`,
                        width: 43,
                        height: 43,
                    }}
                    icon={<UserOutlined />}
                    size="large"
                />
            );
        }

        const { profilePicture, initials } = viewer || {};

        return (
            <Avatar
                style={{
                    color: PRIMARY,
                    backgroundColor: '#fff',
                    border: `2px solid ${PRIMARY}`,
                    width: 43,
                    height: 43,
                }}
                size="large"
                src={profilePicture}
                alt="avatar"
            >
                {initials}
            </Avatar>
        );
    }
}

export default graphql(CurrentConsumer)(ConsumerProfilePostAvatar);
