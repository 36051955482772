import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Tabs } from 'antd';
import useWindowSize from '~/utils/useWindowSize';
import AgentPropertiesSection from '~/components/AgentPropertiesSection';
import AgentPropertyTab from '../AgentPropertyTab';
import './AgentProperties.module.scss';

const AgentProperties = ({ agentId }) => {
    const { width } = useWindowSize();
    return (
        <section className="AgentProperties bg-white px-0 sm:px-4">
            <Row>
                <Col xs={24}>
                    {width > 600 ? (
                        <>
                            <AgentPropertiesSection agentId={agentId} group="BUY" />
                            <AgentPropertiesSection agentId={agentId} group="RENT" />
                            <AgentPropertiesSection agentId={agentId} group="SOLD" />
                        </>
                    ) : (
                        <Tabs
                            defaultActiveKey="sale"
                            size="large"
                            className="property-tabs"
                            style={{ backgroundColor: '#FAFAFA' }}
                        >
                            <Tabs.TabPane tab="For Sale" key="sale">
                                <AgentPropertyTab group="BUY" id={agentId} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Sold" key="sold">
                                <AgentPropertyTab group="SOLD" id={agentId} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="For Rent" key="rent">
                                <AgentPropertyTab group="RENT" id={agentId} />
                            </Tabs.TabPane>
                        </Tabs>
                    )}
                </Col>
            </Row>
        </section>
    );
};

AgentProperties.propTypes = { agentId: PropTypes.string.isRequired };

export default AgentProperties;
